<template>
  <div>
    <!-- gambar rumah -->
    <v-dialog v-model="dialog.gambar_rumah"
      persistent
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        class="border-radius pa-2">
        <v-card-title
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            Gambar Rumah
          </span>
        </v-card-title>

        <v-divider
          class="mx-4 mt-3 mb-2"/>

        <v-card-text
          class="body-2 text-first line-text-second px-4 pb-2 mt-4">
          <div>
            <v-img v-if="selected.gambar_rumah_index === ''"
              contain
              width="100%"
              height="250"
              style="border: 5px solid #0000000d; border-radius: 10px;"
              alt="bidanvitacare"
              :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
              class="ma-auto">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <v-img v-else
              contain
              width="100%"
              height="250"
              style="border: 5px solid #0000000d; border-radius: 10px;"
              alt="bidanvitacare"
              :src="JSON.parse(detail.address_image_array_json)[selected.gambar_rumah_index].image_url"
              class="ma-auto">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>

          <div v-if="detail.address_image_array_json.length > 0"
            class="mt-2">
            <no-ssr>
              <swiper
                class="swiper"
                ref="swiper"
                :options="{ slidesPerView: 'auto' }">
                <swiper-slide
                  class="py-3 pl-0"
                  style="max-width: 120px;">
                  <v-img
                    @click="selected.gambar_rumah_index = ''"

                    contain
                    width="100%"
                    height="100px"
                    style="border: 5px solid #0000000d; border-radius: 10px;"
                    alt="bidanvitacare"
                    :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
                    class="ma-auto cursor-pointer">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <v-icon v-if="selected.gambar_rumah_index === ''"
                      color="green"
                      style="position: absolute; right: 0;">
                      mdi-check-circle
                    </v-icon>
                  </v-img>
                </swiper-slide>

                <swiper-slide
                  v-for="(item, index) in JSON.parse(detail.address_image_array_json)"
                  :key="index"
                  class="py-3"
                  :class="index === 0 ? 'pl-4 pr-4' : index < JSON.parse(detail.address_image_array_json).length - 1 ? 'pr-4' : 'pr-4'"
                  style="max-width: 120px;">
                  <v-img
                    @click="selected.gambar_rumah_index = index"

                    contain
                    width="100%"
                    height="100px"
                    alt="bidanvitacare"
                    :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                    class="ma-auto cursor-pointer"
                    style="border: 5px solid #0000000d; border-radius: 10px;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <v-icon v-if="selected.gambar_rumah_index === index"
                      color="green"
                      style="position: absolute; right: 0;">
                      mdi-check-circle
                    </v-icon>
                  </v-img>
                </swiper-slide>
              </swiper>
            </no-ssr>
          </div>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.gambar_rumah = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Tutup
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="pb-0 mt-1">
          <v-text-field
            v-model="search_data"
            @click:clear="search_data = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <v-row>
            <v-col
              cols="12">
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 4 : list_data"
                :key="index"
                :loading="process.load"
                :class="index < list_data.length - 1 ? 'mb-4' : ''"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-card v-if="!process.load && list_data.length > 0"
                      @click="sheet.detail = true; fetchDetail(item.id);"
                      flat
                      color="transparent">
                      <div
                        class="d-flex align-center">
                        <div
                          class="font-weight-bold black--text">
                          <span v-if="item.datetime !== ''">
                            {{ item.datetime | date }}
                          </span>

                          <span v-else>
                            Tanggal belum ditentukan
                          </span>
                        </div>

                        <v-spacer />

                        <div v-if="item.datetime !== ''"
                          class="font-weight-bold">
                          {{ item.datetime | time }}
                        </div>
                      </div>

                      <div>
                        <v-divider
                          class="my-2"
                          style="border-top: 1px dashed #bbb;" />
                      </div>

                      <div
                        class="d-flex align-center mb-1">
                        <div
                          class="d-flex align-center">
                          <v-avatar
                            size="22"
                            style="border: 2px solid #ffffff;">
                            <v-img
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <span
                            class="ml-1">
                            {{ item.customer_name }}
                          </span>
                        </div>

                        <v-spacer />

                        <div>
                          {{ item.store_name }}
                        </div>
                      </div>

                      <div>
                        <v-divider
                          class="my-2"
                          style="border-top: 1px dashed #bbb;" />
                      </div>

                      <div
                        class="mt-3 mb-2 pa-3 pt-2"
                        style="border: 2px dashed #bbb; border-radius: 10px;">
                        <v-chip
                          x-small
                          class="white--text"
                          :color="item.type === 'on_site' ? 'blue' : 'green'"
                          style="font-weight: 500;">
                          {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                        </v-chip>

                        <div
                          v-for="(sub_item, sub_index) in item.details"
                          :key="sub_index">
                          <div
                            class="d-flex align-start my-1">
                            <v-avatar
                              tile
                              size="22"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="sub_item.item_image_url !== '' ? sub_item.item_image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="ml-1">
                              {{ sub_item.item_name }}
                            </span>
                          </div>

                          <div
                            class="d-flex align-center"
                            style="margin-left: 26px;">
                            <div>
                              Durasi. <strong>{{ sub_item.duration_minutes }} Menit</strong>
                            </div>

                            <v-spacer />

                            <div>
                              Qty. <strong>{{ sub_item.qty }}x</strong>
                            </div>

                            <v-spacer />

                            <div>
                              {{ sub_item.therapist_shift }}
                            </div>
                          </div>

                          <v-divider v-if="sub_index < item.details.length - 1"
                            class="my-2"
                            style="border-top: 1px dashed #bbb;" />
                        </div>
                      </div>

                      <v-row>
                        <v-col
                          cols="12"
                          class="pt-1">
                          <v-card
                            flat
                            color="#FFFFFF40"
                            class="pa-3"
                            style="border: 2px dashed #bbb; border-radius: 10px;">
                            <div
                              class="d-flex align-center">
                              <div>
                                Klien
                              </div>

                              <v-spacer />

                              <div v-if="item.client_name !== ''"
                                class="d-flex align-center">
                                <v-avatar
                                  size="22"
                                  style="border: 2px solid #ffffff;">
                                  <v-img
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="item.client_image_url !== '' ? item.client_image_url : require('@/assets/image/member_default.jpg')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <span
                                  class="ml-1">
                                  {{ item.client_name }}
                                </span>
                              </div>

                              <div v-else>
                                -
                              </div>
                            </div>

                            <div v-if="item.address_link_map !== ''">
                              <v-divider
                                class="my-3"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div v-if="item.address_link_map !== ''"
                              class="d-flex align-center">
                              <div>
                                Alamat
                              </div>

                              <v-spacer />

                              <div>
                                <a
                                  :href="item.address_link_map"
                                  target="_blank">
                                  Lihat Lokasi
                                </a>
                              </div>
                            </div>

                            <div v-if="item.type === 'home_care'">
                              <v-divider
                                class="my-3"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div v-if="item.type === 'home_care'">
                              <div v-if="item.transport_id !== '' && item.transport_id !== '0'"
                                class="d-flex align-center">
                                <div>
                                  Transport ({{ item.transport_qty }} x {{ Number(item.transport_cost) | price }})
                                </div>

                                <v-spacer />

                                <div class="font-weight-bold">
                                  {{ Number(item.transport_total_cost) | price }}
                                </div>
                              </div>

                              <div v-else
                                class="d-flex align-center">
                                <div>
                                  Transport
                                </div>

                                <v-spacer />

                                <div class="font-weight-bold">
                                  -
                                </div>
                              </div>
                            </div>

                            <div>
                              <v-divider
                                class="my-3"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div
                              class="d-flex align-center">
                              <div>
                                Penugasan
                              </div>

                              <v-spacer />

                              <div class="text-uppercase font-weight-bold">
                                {{ !item.is_therapist_partner_bool ? 'Single' : 'Partner' }}
                              </div>
                            </div>

                            <div>
                              <v-divider
                                class="my-3"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div
                              class="d-flex align-center">
                              <div>
                                Down Payment
                              </div>

                              <v-spacer />

                              <div class="text-uppercase font-weight-bold">
                                <span v-if="Number(item.dp) > 0">
                                  {{ Number(item.dp) | price }}
                                </span>

                                <span v-else>
                                  -
                                </span>
                              </div>
                            </div>

                            <div>
                              <v-divider
                                class="my-3"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div
                              class="d-flex align-center">
                              <div>
                                Diinput Oleh
                              </div>

                              <v-spacer />

                              <div>
                                {{ item.create_user_name }}
                              </div>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="!process.load && pagination_data.total_data > 10 && limit_data < pagination_data.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit_data += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && pagination_data.total_page < 1"
            class="py-6"
            margin="my-16"
            size="125"
            message="Jadwal" />
        </v-card-text>
      </v-card>
    </section>

    <!-- detail jadwal -->
    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.detail = false; detail = {};"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.detail = false; detail = {};"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Jadwal {{ detail.customer_name }} - {{ detail.client_name }}
            </span>
          </v-card-title>

          <v-card-text
            class="mt-16">
            <div v-if="!process.load && Object.keys(detail).length > 0"
              style="height: calc(100vh - 70px); overflow: auto;">
              <v-card
                flat
                color="transparent"
                class="py-0"
                style="border-radius: 10px;">
                <v-list
                  flat
                  dense
                  color="transparent">
                  <v-list-item
                    class="px-0">
                    <v-list-item-avatar
                      size="40"
                      style="border: 2px solid #ffffff; overflow: unset;">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="detail.customer_image_url !== '' ? detail.customer_image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        class="body-2 font-weight-bold text-capitalize mb-2">
                        {{ detail.customer_name }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="body-2 font-weight-light black--text">
                        {{ detail.customer_mobilephone }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>

              <v-divider
                class="my-1"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center my-4">
                <div>
                  <div
                    class="line-text-second">
                    {{ detail.type === 'on_site' ? 'On Site' : 'Home Care' }}
                  </div>

                  <div
                    class="caption font-weight-bold">
                    {{ detail.datetime | datetime }}
                  </div>
                </div>

                <v-spacer />

                <v-chip
                  small
                  class="white--text"
                  color="green">
                  {{ detail.details.length }}x Layanan
                </v-chip>
              </div>

              <v-divider
                class="my-1"
                style="border-top: 1px dashed #bbb;" />

              <!-- item -->
              <v-row>
                <v-col
                  v-for="(item, index) in detail.details"
                  :key="index"
                  cols="12">
                  <v-card
                    flat
                    color="#FFFFFF40"
                    class="pa-3 pt-2"
                    style="border: 2px dashed #bbb; border-radius: 10px;">
                    <v-list
                      flat
                      dense
                      three-line
                      color="transparent"
                      class="py-0">
                      <v-list-item
                        class="px-0">
                        <v-list-item-avatar
                          size="40"
                          tile
                          class="mt-3"
                          style="border: 2px solid #ffffff; border-radius: 5px !important;">
                          <v-img
                            contain
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="item.item_image_url !== '' ? item.item_image_url : require('@/assets/image/image_default.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <div
                            class="mb-2">
                            <v-chip
                              x-small
                              color="#424242"
                              class="white--text d-flex justify-center"
                              style="width: 65px;">
                              {{ item.type === 'service' ? 'Layanan' : item.type === 'product' ? 'Produk' : 'Bahan' }}
                            </v-chip>
                          </div>

                          <div
                            class="body-2 text-capitalize">
                            <div
                              class="two-line font-weight-bold mb-1">
                              {{ item.item_name }}
                            </div>

                            <div
                              class="d-flex align-center">
                              <div>
                                Durasi. <strong>{{ item.duration_minutes }} Menit</strong>
                              </div>

                              <v-spacer />

                              <div>
                                Qty. <strong>{{ item.qty }}x</strong>
                              </div>

                              <v-spacer />

                              <div>
                                {{ item.therapist_shift }}
                              </div>
                            </div>
                          </div>

                          <div>
                            <v-divider
                              class="my-3"
                              style="border-top: 1px dashed #bbb;" />
                          </div>

                          <div
                            class="d-flex align-center">
                            <div
                              class="body-2">
                              Klien
                            </div>

                            <v-spacer />

                            <div>
                              <div
                                class="d-flex align-center">
                                <v-avatar
                                  size="24"
                                  class="mt-0"
                                  style="border: 2px solid #ffffff;">
                                  <v-img
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="detail.client_image_url !== '' ? detail.client_image_url : require('@/assets/image/member_default.jpg')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <span
                                  class="caption black--text font-weight-bold one-line text-capitalize ml-1">
                                  {{ detail.client_name }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-divider
                      class="my-2"
                      style="border-top: 1px dashed #bbb;" />

                    <div
                      class="my-3">
                      {{ item.note !== '' ? item.note : 'Tidak ada catatan' }}
                    </div>

                    <v-divider
                      class="my-2"
                      style="border-top: 1px dashed #bbb;" />

                    <!-- alat -->
                    <div
                      class="my-4">
                      <div
                        class="body-2 mb-2">
                        Alat yang harus dibawa terapis
                      </div>

                      <div
                        class="body-2 text-capitalize">
                        <ul v-if="item.material_json_array.length > 0"
                          style="position: relative; left: -10px;">
                          <li
                            v-for="(sub_item, sub_index) in item.material_json_array"
                            :key="sub_index"
                            :class="sub_index < item.material_json_array.length - 1 ? 'mb-1' : ''"
                            v-html="sub_item.name">
                          </li>
                        </ul>

                        <div v-else>
                          -
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        class="body-2 mb-2">
                        Alat yang harus dipersiapkan customer
                      </div>

                      <div
                        class="body-2 text-capitalize">
                        <ul v-if="item.customer_prepare_json_array.length > 0"
                          style="position: relative; left: -10px;">
                          <li
                            v-for="(sub_item, sub_index) in item.customer_prepare_json_array"
                            :key="sub_index"
                            :class="sub_index < item.customer_prepare_json_array.length - 1 ? 'mb-1' : ''"
                            v-html="sub_item.value">
                          </li>
                        </ul>

                        <div v-else>
                          -
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>

              <v-divider
                class="my-1"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="mt-4 mb-2">
                Jlm Item ({{ detail.details.length }})
              </div>

              <!-- dp -->
              <div
                class="d-flex align-center mb-4">
                <div>
                  Down Payment
                </div>

                <v-spacer />

                <div>
                  {{ detail.dp | price }}
                </div>
              </div>

              <v-divider v-if="detail.type === 'home_care'"
                class="my-1"
                style="border-top: 1px dashed #bbb;" />

              <!-- alamat -->
              <div v-if="detail.type === 'home_care'">
                <div
                  class="font-weight-bold mt-4 mb-2">
                  Alamat
                </div>

                <div
                  class="mb-2">
                  {{ detail.address_full !== '' ? detail.address_full : '-'}}
                </div>

                <div
                  class="mb-2">
                  {{ detail.address_subdistrict_name }}, {{ detail.address_city_name }}, {{ detail.address_province_name }}
                </div>

                <div
                  class="mb-4">
                  Keterangan. {{ detail.address_note !== '' ? detail.address_note : '-'}}
                </div>
              </div>

              <div v-if="detail.type === 'home_care'">
                <div
                  class="font-weight-bold mt-4 mb-2">
                  Gambar Rumah
                </div>

                <div
                  class="text-truncate mb-4">
                  <span v-if="detail.address_image_url !== ''"
                    @click="dialog.gambar_rumah = true"
                    class="cursor-pointer"
                    style="color: #1976d3;">
                    Lihat disini
                  </span>

                  <span v-else>
                    -
                  </span>
                </div>
              </div>

              <div v-if="detail.type === 'home_care'">
                <div
                  class="font-weight-bold mt-4 mb-2">
                  Link Map
                </div>

                <div
                  class="text-truncate mb-4">
                  <a v-if="detail.address_link_map !== ''"
                    :href="detail.address_link_map"
                    target="_blank">
                    {{ detail.address_link_map }}
                  </a>

                  <span v-else>
                    -
                  </span>
                </div>
              </div>

              <v-divider v-if="detail.type === 'home_care'"
                class="my-1"
                style="border-top: 1px dashed #bbb;" />

              <!-- transport -->
              <div v-if="detail.type === 'home_care'"
                class="my-4">
                <div class="font-weight-bold mb-2">
                  Transport
                </div>

                <div v-if="detail.transport_id !== '' && detail.transport_id !== '0'">
                  <div
                    class="d-flex align-center mb-1">
                    <div>
                      {{ detail.transport_name }}
                    </div>

                    <v-spacer />

                    <div>
                      {{ Number(detail.transport_cost) | price }}
                    </div>
                  </div>

                  <div
                    class="d-flex align-center">
                    <div>
                      Qty ({{ detail.transport_qty }}x)
                    </div>

                    <v-spacer />

                    <div
                      class="font-weight-bold black--text">
                      {{ Number(detail.transport_total_cost) | price }}
                    </div>
                  </div>
                </div>

                <div v-else>
                  Transport belum ditentukan
                </div>
              </div>

              <v-divider
                class="my-1"
                style="border-top: 1px dashed #bbb;" />

              <!-- pendamping -->
              <div
                class="font-weight-bold mt-4 mb-2">
                Pendamping
              </div>

              <div
                class="d-flex align-center mb-2">
                <div>
                  Nama Pendamping
                </div>

                <v-spacer />

                <div
                  class="text-capitalize">
                  {{ detail.wali !== '' ? detail.wali : '-' }}
                </div>
              </div>

              <!-- <div
                class="d-flex align-center mb-2">
                <div>
                  No. Handphone Pendamping
                </div>

                <v-spacer />

                <div>
                  {{ detail.mobilephone_wali !== '' ? detail.mobilephone_wali : '-' }}
                </div>
              </div>

              <div
                class="d-flex align-center mb-2">
                <div>
                  No. Handphone Lainnya
                </div>

                <v-spacer />

                <div>
                  {{ detail.mobilephone_other !== '' ? detail.mobilephone_other : '-' }}
                </div>
              </div> -->

              <!-- kpsp -->

              <v-divider v-if="detail.kpsp.length > 0"
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div v-if="detail.kpsp.length > 0">
                <div
                  class="mb-2">
                  <div
                    class="body-2 black--text font-weight-bold mb-1">
                    KPSP
                  </div>

                  <div>
                    Berikut ini adalah data kpsp.
                  </div>
                </div>

                <v-list
                  v-for="(item, index) in detail.kpsp"
                  :key="index"
                  flat
                  dense
                  two-line
                  color="#FFFFFF40"
                  class="border-radius pa-0">
                  <v-list-item
                    class="pa-0">
                    <v-list-item-content>
                      <v-list-item-title
                        class="body-2 font-weight-bold text-capitalize">
                        {{ item.kpsp_type }} - {{ item.kpsp_age_month }} Bulan
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="body-2 font-weight-light line-text-second black--text"
                        style="opacity: 70%;">
                        {{ item.kpsp_question }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle
                        class="body-2 font-weight-bold">
                        Jawaban:

                        <span
                          :class="item.kpsp_answer === 'YA' ? 'green--text' : item.kpsp_answer === 'TIDAK' ? 'red--text' : ''">
                          {{ item.kpsp_answer !== '' ? item.kpsp_answer : '-' }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <!-- laporan -->
              <div
                class="mb-4">
                <div
                  class="body-2 black--text font-weight-bold mb-1">
                  Laporan
                </div>

                <div>
                  Berikut ini adalah data laporan.
                </div>
              </div>

              <div>
                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Catatan Kejadian
                  </div>

                  <div>
                    {{ detail.laporan_catatan_kejadian !== '' && detail.laporan_catatan_kejadian !== null ? detail.laporan_catatan_kejadian : '-' }}
                  </div>
                </div>

                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Perihal Kesehatan Ibu & Anak yang ditanyakan
                  </div>

                  <div>
                    {{ detail.laporan_perihal_kesehatan !== '' && detail.laporan_perihal_kesehatan !== null ? detail.laporan_perihal_kesehatan : '-' }}
                  </div>
                </div>

                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Pertanyaan kepo tentang manajemen
                  </div>

                  <div>
                    {{ detail.laporan_pertanyaan_kepo !== '' && detail.laporan_pertanyaan_kepo !== null ? detail.laporan_pertanyaan_kepo : '-' }}
                  </div>
                </div>

                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Kesalahan yang dilakukan Terapis
                  </div>

                  <div>
                    {{ detail.laporan_kesalahan_therapist !== '' && detail.laporan_kesalahan_therapist !== null ? detail.laporan_kesalahan_therapist : '-' }}
                  </div>
                </div>

                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Komplain Klien secara langsung
                  </div>

                  <div>
                    {{ detail.laporan_komplain !== '' && detail.laporan_komplain !== null ? detail.laporan_komplain : '-' }}
                  </div>
                </div>

                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Masukkan untuk manajemen bidanvitacare / kendala yang dihadapi saat homecare atau onsite
                  </div>

                  <div>
                    {{ detail.laporan_masukan !== '' && detail.laporan_masukan !== null ? detail.laporan_masukan : '-' }}
                  </div>
                </div>

                <div>
                  <div
                    class="font-weight-bold black--text mb-1">
                    Promosi
                  </div>

                  <div>
                    {{ detail.laporan_promosi !== '' && detail.laporan_promosi !== null ? detail.laporan_promosi : '-' }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: {
          go_back: false,

          gambar_rumah: false
        },

        sheet: {
          detail: false
        },

        selected: {
          gambar_rumah_index: ''
        },

        search_data: '',

        detail: {},

        list_data: [],
        pagination_data: {},
        limit_data: 10,

        process: {
          load: false
        },

        message: {
          success: '',
          error: '',
          errors: []
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit_data': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search_data,
          limit: this.limit_data
        }

        await this.$axios.$get(`${process.env.API}therapist/schedule_v3/data_visit_tomorrow`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list_data = response.results.data
            this.pagination_data = response.results.pagination
          }
        })
      },

      async fetchDetail (id) {
        this.process.load = true

        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}therapist/schedule_v3/data_visit_detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      }
    }
  }
</script>
