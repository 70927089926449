<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.partner_or_terapis"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                {{ selected.type_user }}
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text v-if="selected.partner_or_terapis.length > 0"
              class="body-2 text-first line-text-second px-4 pb-2">
              <v-row>
                <v-col
                  v-for="(item, index) in selected.partner_or_terapis"
                  :key="index"
                  cols="12">
                  <v-list
                    flat
                    dense
                    color="#FFFFFF40"
                    class="border-radius"
                    style="border: 1px dashed #bbb;">
                    <v-list-item
                      v-if="selected.type_user === 'Klien'">
                      <v-list-item-avatar
                        size="40"
                        style="border: 2px solid #ffffff;">
                        <v-img
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 font-weight-bold text-capitalize">
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="selected.type_user === 'Terapis'">
                      <v-list-item-avatar
                        size="40"
                        style="border: 2px solid #ffffff;">
                        <v-img
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 font-weight-bold text-capitalize">
                          {{ item.user_name }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          class="body-2 font-weight-light text-capitalize line-text-second black--text"
                          style="opacity: 70%;">
                          {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <!-- <v-list-item-action>
                        <v-btn
                          @click="form.customer_name = detail.customer_name; form.therapist_id = item.user_id; prints();"
                          :loading="process.print && form.therapist_id === item.user_id"
                          :disabled="process.print"
                          small
                          rounded
                          :color="set_color"
                          elevation="0"
                          class="text-capitalize white--text font-weight-bold">
                          Print
                        </v-btn>
                      </v-list-item-action> -->
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.partner_or_terapis = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Tutup
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.payment_proof"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                {{ !detail.is_checked_bool ? 'Unggah' : '' }} Bukti Transfer
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="mt-2">
              <!-- BELUM DICEK -->
              <div v-if="!detail.is_checked_bool">
                <div
                  class="boddy-2 mb-2">
                  Gambar 1
                </div>

                <upload-folder
                  v-model="upload.folder"
                  class="mb-6"
                  style="width: 100%;">
                  <div
                    slot="activator"
                    class="cursor-pointer d-flex align-center justify-center"
                    style="border: 5px solid #0000000d;
                          border-radius: 10px;
                          min-width: 100%;
                          width: 100%;
                          height: 400px;">
                    <v-img v-if="!process.file && form_payment_proof.proof_image_url !== ''"
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 6px;"
                      alt="bidanvitacare"
                      :src="form_payment_proof.proof_image_url !== '' ? form_payment_proof.proof_image_url : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>

                      <v-icon
                        size="50"
                        color="grey">
                        mdi-cloud-upload-outline
                      </v-icon>
                    </v-img>

                    <v-icon v-if="!process.file && form_payment_proof.proof_image_url === ''"
                      size="50"
                      color="grey">
                      mdi-cloud-upload-outline
                    </v-icon>

                    <v-progress-circular v-if="process.file"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </div>
                </upload-folder>

                <div
                  class="boddy-2 mb-2">
                  Gambar 2
                </div>

                <upload-folder
                  v-model="upload.folder_2"
                  style="width: 100%;">
                  <div
                    slot="activator"
                    class="cursor-pointer d-flex align-center justify-center"
                    style="border: 5px solid #0000000d;
                          border-radius: 10px;
                          min-width: 100%;
                          width: 100%;
                          height: 400px;">
                    <v-img v-if="!process.file_2 && form_payment_proof.proof_2_image_url !== ''"
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 6px;"
                      alt="bidanvitacare"
                      :src="form_payment_proof.proof_2_image_url !== '' ? form_payment_proof.proof_2_image_url : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>

                      <v-icon
                        size="50"
                        color="grey">
                        mdi-cloud-upload-outline
                      </v-icon>
                    </v-img>

                    <v-icon v-if="!process.file_2 && form_payment_proof.proof_2_image_url === ''"
                      size="50"
                      color="grey">
                      mdi-cloud-upload-outline
                    </v-icon>

                    <v-progress-circular v-if="process.file_2"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </div>
                </upload-folder>
              </div>

              <!-- SUDAH DICEK -->
              <div v-if="detail.is_checked_bool">
                <div
                  class="boddy-2 mb-2">
                  Gambar 1
                </div>

                <v-img
                  contain
                  width="100%"
                  height="100%"
                  style="border-radius: 6px;"
                  alt="bidanvitacare"
                  :src="form_payment_proof.proof_image_url !== '' ? form_payment_proof.proof_image_url : require('@/assets/image/image_default.png')"
                  class="ma-auto d-flex align-center text-center mb-6">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="boddy-2 mb-2">
                  Gambar 2
                </div>
                <v-img
                  contain
                  width="100%"
                  height="100%"
                  style="border-radius: 6px;"
                  alt="bidanvitacare"
                  :src="form_payment_proof.proof_2_image_url !== '' ? form_payment_proof.proof_2_image_url : require('@/assets/image/image_default.png')"
                  class="ma-auto d-flex align-center text-center">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>


              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <!-- <v-btn v-if="form_payment_proof.proof_image_url !== '' && !detail.is_checked_bool"
                :loading="process.form_payment_proof_cek"
                :disabled="process.form_payment_proof_cek || process.form_payment_proof"
                v-on:keyup.enter="save_form_payment_proof_cek()"
                @click="save_form_payment_proof_cek()"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <v-icon
                    size="20">
                    mdi-eye-check-outline
                  </v-icon>

                  <span
                    class="ml-1">
                    Cek
                  </span>
                </div>
              </v-btn> -->

              <v-spacer/>

              <v-btn v-if="menu[selected.menu] !== 'Sudah Dicek'"
                :loading="process.form_payment_proof"
                :disabled="process.form_payment_proof || process.form_payment_proof_cek"
                v-on:keyup.enter="save_payment_proof()"
                @click="save_payment_proof()"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.payment_proof = false"
                :disabled="process.form_payment_proof || process.form_payment_proof_cek"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.success"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold ml-2">
                Unggah Bukti Berhasil
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              Terima kasih, Anda telah mengunggah bukti transfer dari transaksi di Bidanvitacare.id
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.success = false; fetchDetail(detail.id);"
                rounded
                elevation="0"
                class="body-2 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in d-flex align-center">
                  <v-icon
                    size="16"
                    class="mr-1">
                    mdi-close-thick
                  </v-icon>

                  Tutup
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.refund"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin refund tansaksi ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="refunded()"
                :loading="process.refund"
                :disabled="process.refund"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.refund = false"
                :disabled="process.refund"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.cancel"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin cancel tansaksi ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="canceled()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.cancel = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.filter"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Filter
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              <div>
                <div class="body-2 mb-2">
                  Pilih Cabang
                </div>

                <v-select
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Pilih Cabang"

                  hide-details

                  :items="store"
                  item-value="id"
                  item-text="name"
                  :item-color="set_color"

                  clearable
                  @click:clear="filter.store_id = ''"

                  :no-filter="false"

                  v-model="filter.store_id">
                </v-select>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.filter = false; fetch();"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.filter = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="pb-0 mt-1 px-0">
              <no-ssr>
                <swiper
                  class="swiper"
                  ref="swiper"
                  :options="{ slidesPerView: 'auto' }">
                  <swiper-slide
                    v-for="(item, index) in menu"
                    :key="index"
                    :class="index === 0 ? 'pl-4 pr-4' : index < menu.length - 1 ? 'pr-4' : 'pr-4'"
                    style="max-width: 130px;">
                    <div>
                      <v-btn
                        @click="selected.menu = index; fetch();"
                        width="100"
                        rounded
                        elevation="0"
                        color="#ececec"
                        class="body-2 font-weight-bold text-capitalize mb-2"
                        :class="selected.menu === index ? `color: ${set_color};` : ''"
                        :style="selected.menu === index ? `border: 2px dashed ${set_color}; color: ${set_color};` : ''">
                        {{ item }}
                      </v-btn>
                    </div>
                  </swiper-slide>
                </swiper>
              </no-ssr>

              <!-- <v-row
                class="px-1">
                <v-col
                  v-for="(item, index) in menu"
                  :key="index"
                  cols="6"
                  class="py-0 px-2">
                  <v-btn
                    @click="selected.menu = index; fetch();"
                    block
                    rounded
                    elevation="0"
                    :class="selected.menu !== index ? '' : 'font-weight-bold'"
                    :color="selected.menu !== index ? '#e2e2e2' : set_color"
                    :style="selected.menu !== index ? 'color: #8c8c8c;' : 'color: #FFFFFF;'"
                    class="body-2 text-capitalize mb-2">
                    {{ item }}
                  </v-btn>
                </v-col>
              </v-row> -->
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="transparent">
            <v-card-text
              class="pb-0 mt-1 d-flex align-center">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-btn
                @click="dialog.filter = true; fetchStore();"
                width="40"
                height="40"
                fab
                elevation="0"
                class="ml-3">
                <v-icon
                  style="opacity: 60%;">
                  mdi-filter-variant
                </v-icon>
              </v-btn>
            </v-card-text>

            <v-card-text
              class="pb-0"
              style="height: calc(100vh - 125px); overflow: auto;">
              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item
                            @click="sheet.detail = true; fetchDetail(item.id);">
                            <v-list-item-content>
                              <v-list-item-subtitle
                                class="body-2 black--text text-capitalize d-flex align-center">
                                <v-avatar
                                  size="30"
                                  style="border: 2px solid #ffffff;">
                                  <v-img
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <div
                                  class="ml-2 text-truncate">
                                  {{ item.customer_name }}
                                </div>

                                <v-spacer />

                                <div>
                                  <v-chip
                                    small
                                    class="white--text"
                                    :color="item.status === 'complete' ? 'green' :
                                            item.status === 'refund' ? 'orange' :
                                            item.status === 'cancel' ? 'red' : 'grey'">
                                    {{ item.status }}
                                  </v-chip>
                                </div>
                              </v-list-item-subtitle>

                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize my-2 py-3 d-flex align-center"
                                style="border-top: 1px dashed #bbb; border-bottom: 1px dashed #bbb;">
                                <div>
                                  {{ item.code }}
                                </div>

                                <v-spacer />

                                <div>
                                  <div
                                    class="d-flex align-center">
                                    <span class="caption mr-1"
                                      style="opacity: 60%;">
                                      ({{ item.rating }})
                                    </span>

                                    <v-rating
                                      dense
                                      :value="item.rating"
                                      size="18"
                                      readonly
                                      color="yellow accent-4"
                                      background-color="#E0E0E0"
                                      empty-icon="$ratingFull">
                                    </v-rating>
                                  </div>
                                </div>
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text"
                                style="opacity: 70%;">
                                Jlm Item. {{ item.total_item_qty }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="d-flex align-center">
                                <div
                                  class="body-2 ">
                                  Total. <strong class="black--text">{{ item.grand_total | price }}</strong>
                                </div>

                                <v-spacer />

                                <div
                                  class="caption">
                                  {{ item.create_datetime | datetime }}
                                </div>
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2 my-2 py-3"
                                style="border-top: 1px dashed #bbb;">
                                <div
                                  v-for="(item_payment, index) in item.payments"
                                  :key="index">
                                  <div
                                    class="d-flex align-center">
                                    <div
                                      class="font-weight-light">
                                      Metode Pembayaran
                                    </div>

                                    <v-spacer />

                                    <div
                                      class="text-capitalize">
                                      {{ item_payment.method }}
                                    </div>
                                  </div>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Transaksi" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.detail = false; detail = {};"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.detail = false; detail = {};"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Order {{ detail.customer_name }}
                </span>
              </v-card-title>

              <v-card-text
                class="mt-16"
                style="overflow: auto;"
                :style="detail.status === 'complete' ? 'height:calc(100vh - 255px);' : 'height:calc(100vh - 65px);'">
                <v-skeleton-loader
                  :loading="process.detail"
                  type="list-item-avatar-three-line, article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, article, actions">
                  <div>
                    <div v-if="!process.detail && Object.keys(detail).length > 0">
                      <v-card
                        flat
                        color="transparent"
                        class="py-0"
                        style="border-radius: 10px;">
                        <v-list
                          flat
                          dense
                          color="transparent">
                          <v-list-item
                            class="px-0">
                            <v-list-item-avatar
                              size="40"
                              style="border: 2px solid #ffffff; overflow: unset;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="detail.customer_image_url !== '' ? detail.customer_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize mb-2">
                                {{ detail.customer_name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light black--text">
                                {{ detail.customer_mobilephone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                              <div
                                class="d-flex align-center">
                                <v-btn
                                  :to="`/invoice/${detail.uuid}`"
                                  target="_blank"
                                  min-width="36"
                                  width="36"
                                  height="36"
                                  rounded
                                  outlined
                                  elevation="0"
                                  :color="set_color"
                                  class="text-capitalize px-0"
                                  :style="`color: ${set_color};`">
                                  <v-icon>mdi-text-box-outline</v-icon>
                                </v-btn>

                                <v-btn
                                  :to="`/invoice/print/${detail.uuid}`"
                                  target="_blank"
                                  min-width="36"
                                  width="36"
                                  height="36"
                                  rounded
                                  elevation="0"
                                  :color="set_color"
                                  class="text-capitalize white--text px-0 ml-2">
                                  <v-icon>mdi-printer-outline</v-icon>
                                </v-btn>

                                <!-- <v-btn
                                  @click="print_nota"
                                  :loading="process.print"
                                  :disabled="process.print"
                                  min-width="36"
                                  width="36"
                                  height="36"
                                  rounded
                                  elevation="0"
                                  :color="set_color"
                                  class="text-capitalize white--text px-0 ml-2">
                                  <v-icon>mdi-printer-outline</v-icon>
                                </v-btn> -->
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <div
                        class="d-flex align-center mt-4 mb-2">
                        <div
                          class="body-1 font-weight-bold black--text">
                          {{ detail.code }}
                        </div>

                        <v-spacer />

                        <v-chip
                          small
                          class="text-capitalize white--text"
                          :color="detail.status === 'complete' ? 'green' :
                                  detail.status === 'refund' ? 'orange' :
                                  detail.status === 'cancel' ? 'red' : 'grey'">
                          {{ detail.status }}
                        </v-chip>
                      </div>

                      <div
                        class="mb-4 d-flex align-end">
                        <div>
                          <div
                            class="line-text-second">
                            {{ detail.type === 'on_site' ? 'On Site' : 'Home Care' }}
                          </div>

                          <div
                            class="caption font-weight-bold">
                            {{ detail.create_datetime | datetime }}
                          </div>
                        </div>

                        <v-spacer />

                        <div>
                          <div
                            class="d-flex align-center">
                            <span class="caption mr-1"
                              style="opacity: 60%;">
                              ({{ detail.rating }})
                            </span>

                            <v-rating
                              dense
                              :value="detail.rating"
                              size="18"
                              readonly
                              color="yellow accent-4"
                              background-color="#E0E0E0"
                              empty-icon="$ratingFull">
                            </v-rating>
                          </div>
                        </div>
                      </div>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <v-row>
                        <v-col
                          v-for="(item, index) in detail.items"
                          :key="index"
                          cols="12">
                          <v-card
                            flat
                            color="#FFFFFF40"
                            class="pa-3 pt-2"
                            style="border: 2px dashed #bbb; border-radius: 10px;">
                            <v-list
                              flat
                              dense
                              three-line
                              color="transparent"
                              class="py-0">
                              <v-list-item
                                class="px-0">
                                <v-list-item-avatar
                                  size="40"
                                  tile
                                  class="mt-3"
                                  style="border: 2px solid #ffffff; border-radius: 5px !important;">
                                  <v-img
                                    contain
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <div
                                    class="mb-2">
                                    <v-chip
                                      x-small
                                      color="#424242"
                                      class="white--text d-flex justify-center"
                                      style="width: 65px;">
                                      {{ item.type === 'service' ? 'Layanan' : item.type === 'product' ? 'Produk' : 'Bahan' }}
                                    </v-chip>
                                  </div>

                                  <div
                                    class="body-2 font-weight-bold text-capitalize">
                                    <span
                                      class="two-line">
                                      {{ item.name }}
                                    </span>
                                  </div>

                                  <div
                                    class="caption line-text-second black--text d-flex align-center">
                                    <strong
                                      style="opacity: 60%;">
                                      {{ item.price | price }}
                                    </strong>

                                    <span
                                      class="font-weight-light">
                                      /{{ item.type === 'service' ? 'Durasi' : 'Pcs' }}
                                    </span>
                                  </div>

                                  <div>
                                    <v-divider
                                      class="my-2"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div
                                    class="d-flex align-center mb-2">
                                    <div>
                                      Diskon <span v-if="item.disc_percent > 0">({{ item.disc_percent }}%)</span>
                                    </div>

                                    <v-spacer />

                                    <div
                                      class="body-2 font-weight-bold black--text d-flex align-center cursor-pointer">
                                      {{ item.disc_value | price }}
                                    </div>
                                  </div>

                                  <div
                                    class="d-flex align-center">
                                    <div
                                      class="body-2 font-weight-bold black--text">
                                      Total
                                    </div>

                                    <v-spacer />

                                    <div
                                      class="body-2 font-weight-bold black--text">
                                      {{ item.nett_price | price }}
                                    </div>
                                  </div>

                                  <div v-if="item.customer.length > 0">
                                    <v-divider
                                      class="my-2"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div v-if="item.customer.length > 0"
                                    class="d-flex align-center">
                                    <div>
                                      Klien
                                    </div>

                                    <v-spacer />

                                    <div>
                                      <div
                                        @click="dialog.partner_or_terapis = true;
                                                selected.type_user = 'Klien';
                                                selected.partner_or_terapis = item.customer;"
                                        class="d-flex align-center cursor-pointer">
                                        <v-avatar
                                          v-for="(item_customer, index_customer) in item.customer"
                                          :key="index_customer"
                                          v-show="index_customer < 2"
                                          size="24"
                                          class="mt-0"
                                          style="border: 2px solid #ffffff;"
                                          :style="item.customer.length === 2 && index_customer === 0 ? 'position: absolute; right: 20px; z-index: 2;' :
                                                  item.customer.length > 2 && index_customer === 0 ? 'position: absolute; right: 38px; z-index: 2;' :
                                                  item.customer.length > 2 && index_customer === 1 ? 'position: absolute; right: 22px; z-index: 1;' : ''">
                                          <v-img
                                            width="100%"
                                            height="100%"
                                            alt="bidanvitacare"
                                            :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                            class="ma-auto">
                                            <template v-slot:placeholder>
                                              <v-row
                                                class="fill-height"
                                                align="center"
                                                justify="center">
                                                <v-progress-circular
                                                  size="20"
                                                  indeterminate
                                                  :color="set_color">
                                                </v-progress-circular>
                                              </v-row>
                                            </template>
                                          </v-img>
                                        </v-avatar>

                                        <span v-if="item.customer.length < 2"
                                          class="caption font-weight-bold one-line ml-1"
                                          style="width: 50px;">
                                          {{ item.customer[0].name }}
                                        </span>

                                        <span v-if="item.customer.length > 2"
                                          class="caption font-weight-bold ml-1">
                                          +{{ item.customer.length - 2 }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <v-divider
                                      class="mt-2"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>

                            <div
                              class="d-flex align-start">
                              <div
                                class="caption">
                                {{ item.note !== '' ? item.note : 'Tidak ada catatan' }}
                              </div>

                              <v-spacer />

                              <div v-if="item.type === 'service'">
                                <!-- <div
                                  class="d-flex align-center">
                                  <v-avatar
                                    size="20"
                                    class="mt-0"
                                    style="border: 2px solid #ffffff;">
                                    <v-img
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="item.therapist_image_url !== '' ? item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            size="20"
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span
                                    class="caption font-weight-bold ml-1">
                                    {{ item.therapist_name }}
                                  </span>
                                </div> -->

                                <div v-if="item.visit.length > 0"
                                  @click="dialog.partner_or_terapis = true;
                                          selected.type_user = 'Terapis';
                                          selected.partner_or_terapis = item.visit;"
                                  class="d-flex align-center cursor-pointer">
                                  <v-avatar
                                    v-for="(item_visit, index_visit) in item.visit"
                                    :key="index_visit"
                                    v-show="index_visit < 2"
                                    size="24"
                                    class="mt-0"
                                    style="border: 2px solid #ffffff;"
                                    :style="item.visit.length === 2 && index_visit === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                            item.visit.length > 2 && index_visit === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                            item.visit.length > 2 && index_visit === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                    <v-img
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="item_visit.user_image_url !== '' ? item_visit.user_image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            size="20"
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span v-if="item.visit.length < 2"
                                    class="caption font-weight-bold one-line ml-1"
                                    style="width: 50px;">
                                    {{ item.visit[0].user_name }}
                                  </span>

                                  <span v-if="item.visit.length > 2"
                                    class="caption font-weight-bold ml-1">
                                    +{{ item.visit.length - 2 }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <div
                        class="d-flex align-center mt-4 mb-2">
                        <div>
                          Jlm Item ({{ detail.total_item_qty }})
                        </div>

                        <v-spacer />

                        <div>
                          {{ detail.total_item_subtotal | price }}
                        </div>
                      </div>

                      <div
                        class="d-flex align-center mb-4">
                        <div>
                          Diskon <span v-if="detail.discount_percent > 0">{{ detail.discount_percent }}%</span>
                        </div>

                        <v-spacer />

                        <div>
                          {{ detail.discount_value | price }}
                        </div>
                      </div>

                      <div
                        class="d-flex align-center mb-4">
                        <div
                          class="body-1 font-weight-bold">
                          Total
                        </div>

                        <v-spacer />

                        <div
                          class="body-1 font-weight-bold black--text">
                          {{ detail.nett_price | price }}
                        </div>
                      </div>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <div v-if="detail.type === 'home_care'">
                        <div
                          class="d-flex align-center mt-4 mb-2">
                          <div>
                            Transport
                          </div>

                          <v-spacer />

                          <div>
                            {{ detail.transport_name }}
                          </div>
                        </div>

                        <div
                          class="d-flex align-center mb-2">
                          <div>
                            Biaya
                          </div>

                          <v-spacer />

                          <div>
                            {{ detail.transport_cost | price }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="d-flex align-center"
                        :class="detail.type === 'on_site' ? 'my-4' : 'mb-4'">
                        <div>
                          Down Payment
                        </div>

                        <v-spacer />

                        <div>
                          {{ detail.dp_paid | price }}
                        </div>
                      </div>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <div v-if="user.role === 'admin' || user.role === 'superadmin'"
                        class="d-flex align-center mt-4">
                        <div>
                          Ubah Metode Pembayaran
                        </div>

                        <v-spacer />

                        <div>
                          <v-btn
                            :to="`/transaksi/payment/${detail.id}`"
                            x-small
                            rounded
                            elevation="0"
                            color="blue"
                            class="text-capitalize white--text d-flex align-center">
                            <v-icon small color="white">
                              mdi-credit-card-outline
                            </v-icon>

                            <span
                              class="ml-1">
                              Ubah
                            </span>
                          </v-btn>
                        </div>
                      </div>

                      <div
                        v-for="(item, index) in detail.payments"
                        :key="index">
                        <div
                          class="d-flex align-center"
                          :class="item.method !== 'cash' ? 'mt-4 mb-2' : 'my-4'">
                          <div>
                            Metode Pembayaran
                          </div>

                          <v-spacer />

                          <div
                            class="text-capitalize">
                            {{ item.method }}
                          </div>
                        </div>

                        <div v-if="item.method === 'transfer' || item.method === 'edc'"
                          class="d-flex align-center mb-2">
                          <div>
                            Bank
                          </div>

                          <v-spacer />

                          <div
                            class="text-capitalize">
                            {{ item.method_bank_name }}
                          </div>
                        </div>

                        <div v-if="item.method !== 'cash'"
                          class="d-flex align-center mb-4">
                          <div>
                            Nomor Referensi
                          </div>

                          <v-spacer />

                          <div
                            class="text-capitalize">
                            {{ item.method_ref }}
                          </div>
                        </div>

                        <div v-if="item.method === 'transfer' || item.method === 'qris'"
                          class="d-flex align-center mb-4">
                          <div>
                            Unggah Bukti Transfer
                          </div>

                          <v-spacer />

                          <div>
                            <v-btn
                              @click="dialog.payment_proof = true;
                                    form_payment_proof.id = item.id;
                                    form_payment_proof.proof_image_url = item.proof_image_url;
                                    form_payment_proof.proof_2_image_url = item.proof_2_image_url;"
                              x-small
                              rounded
                              elevation="0"
                              :color="item.proof_image_url === '' ? set_color : 'green'"
                              class="text-capitalize white--text d-flex align-center">
                              <v-icon small color="white">
                                {{ item.proof_image_url === '' ? 'mdi-cloud-upload-outline' : 'mdi-eye-outline' }}
                              </v-icon>

                              <span
                                class="ml-1">
                                {{ item.proof_image_url === '' ? 'Unggah' : 'Lihat' }}
                              </span>
                            </v-btn>
                          </div>
                        </div>

                        <v-divider
                          class="my-1"
                          style="border-top: 1px dashed #bbb;" />
                      </div>

                      <!-- <div
                        class="d-flex align-center"
                        :class="detail.payment_method !== 'cash' ? 'mt-4 mb-2' : 'my-4'">
                        <div>
                          Metode Pembayaran
                        </div>

                        <v-spacer />

                        <div
                          class="text-capitalize">
                          {{ detail.payment_method }}
                        </div>
                      </div>

                      <div v-if="detail.payment_method === 'transfer' || detail.payment_method === 'edc'"
                        class="d-flex align-center mb-2">
                        <div>
                          Bank
                        </div>

                        <v-spacer />

                        <div
                          class="text-capitalize">
                          {{ detail.payment_method_bank_name }}
                        </div>
                      </div>

                      <div v-if="detail.payment_method !== 'cash'"
                        class="d-flex align-center mb-4">
                        <div>
                          Nomor Referensi
                        </div>

                        <v-spacer />

                        <div
                          class="text-capitalize">
                          {{ detail.payment_method_ref }}
                        </div>
                      </div> -->
                    </div>

                    <!-- <v-divider
                      class="my-1"
                      style="border-top: 1px dashed #bbb;" /> -->

                    <div
                      class="d-flex align-center mt-4">
                      <div
                        class="title font-weight-bold">
                        Grand Total
                      </div>

                      <v-spacer />

                      <div
                        class="title font-weight-bold black--text">
                        {{ detail.grand_total | price }}
                      </div>
                    </div>

                    <!-- CEK TRANSAKSI -->
                    <v-card v-if="!detail.is_checked_bool && user.role === 'superadmin'"
                      flat
                      color="#ffa50026"
                      class="pa-3 mt-4 mb-2"
                      style="border: 1px dashed orange; border-radius: 10px;">
                      <div
                        class="d-flex align-center">
                        <div>
                          Untuk cek transaksi klik tombol <strong>Centang</strong> disamping kanan
                        </div>

                        <v-spacer />

                        <v-btn
                          :loading="process.form_payment_proof_cek"
                          :disabled="process.form_payment_proof_cek"
                          v-on:keyup.enter="save_form_payment_proof_cek()"
                          @click="save_form_payment_proof_cek()"
                          fab
                          small
                          color="green"
                          elevation="0"
                          class="body-1 font-weight-bold white--text text-capitalize">
                          <v-icon
                            size="20">
                            mdi-check-bold
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-card>

                    <v-card v-if="detail.is_checked_bool"
                      flat
                      color="#4caf5126"
                      class="pa-3 mt-4 mb-2"
                      style="border: 1px dashed green; border-radius: 10px;">
                      <div
                        class="d-flex align-center">
                        <div>
                          Transaksi diatas sudah di cek oleh admin
                        </div>
                      </div>
                    </v-card>
                  </div>
                </v-skeleton-loader>
              </v-card-text>

              <v-card v-if="detail.status === 'complete'"
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/cancel_payment.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Jika Anda membatalkan transakai ini, 50% biaya akan masuk ke saldo sedekah di Bidanvitacare.id
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="dialog.refund = true"
                        :loading="process.refund"
                        :disabled="process.refund || process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-reload
                          </v-icon>

                          Refund
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="dialog.cancel = true"
                        :loading="process.form"
                        :disabled="process.form || process.refund"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Cancel Order
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          partner_or_terapis: false,

          payment_proof: false,
          success: false,

          go_back: false,

          refund: false,
          cancel: false,

          filter: false
        },
        sheet: {
          detail: false
        },
        search: '',

        filter: {
          store_id: ''
        },

        selected: {
          type_user: 'Partner',
          partner_or_terapis: [],

          menu: 0
        },
        menu: ['Belum Upload', 'Sudah Upload', 'Belum Dicek', 'Sudah Dicek'],

        store:  [],

        detail: {},
        list: [],
        pagination: {},
        limit: 10,

        upload: {
          folder: null,
          folder_2: null
        },

        form: {
          customer_name: '',
          therapist_id: ''
        },
        form_payment_proof: {
          id: '',
          proof_image_url: '',
          proof_2_image_url: ''
        },

        process: {
          load: false,
          detail: false,
          form: false,
          refund: false,

          print: false,

          file: false,
          file_2: false,
          form_payment_proof: false,
          form_payment_proof_cek: false,

          limit: false
        },

        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/transaksi',
          title: 'Transaksi',
          description: 'Transaksi Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form_payment_proof.proof_image_url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      },

      'upload.folder_2': {
        handler: function () {
          if (this.upload.folder_2) {
            this.process.file_2 = this.upload.folder_2.process

            if (!this.process.file_2) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form_payment_proof.proof_2_image_url = this.upload.folder_2.fileuri
            }
          }
        },
        deep: true
      },

      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          'status[neq]': 'draft',
          limit: this.limit,

          count_belum_upload_bukti: this.menu[this.selected.menu] === 'Belum Upload' ? 1 : this.menu[this.selected.menu] === 'Sudah Upload' || this.menu[this.selected.menu] === 'Belum Dicek' ? 0 : '',
          is_checked_bool: this.menu[this.selected.menu] === 'Belum Dicek' ? false : this.menu[this.selected.menu] === 'Sudah Dicek' ? true : '',

          store_id: this.filter.store_id
        }

        await this.$axios.$get(`${process.env.API}admin/order`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        this.process.detail = true

        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/order/detail`, { params })
        .then((response) => {
          this.process.detail = false

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },
      async refunded () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/order/refund`, {
          id: this.detail.id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.sheet.detail = false

            this.fetch()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },
      async canceled () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/order/cancel`, {
          id: this.detail.id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.cancel = false

            this.sheet.detail = false

            this.fetch()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      async prints () {
        this.message.error = ''

        this.process.print = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/order/print-blank-note`, this.form)
        .then((response) => {

          if (response.status === 200) {
            this.process.print = false

            // CONTENT SUCCESS
          } else {
            this.process.print = false
            this.message.error = response.message
          }
        })
      },

      async save_payment_proof () {
        this.message.error = ''

        this.process.form_payment_proof = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/order/update-proof-payment`, this.form_payment_proof)
        .then((response) => {

          if (response.status === 200) {
            this.process.form_payment_proof = false

            this.dialog.payment_proof = false

            this.dialog.success = true
          } else {
            this.process.form_payment_proof = false
            this.message.error = response.message
          }
        })
      },

      print_nota () {
        // this.message.error = ''

        // this.process.print = true

        // let params = {
        //   id: this.detail.id
        // }

        // // SAVE TO REST API
        // await this.$axios.$get(`${process.env.API}admin/order/print-ulang`, { params })
        // .then((response) => {

        //   if (response.status === 200) {
        //     this.process.print = false

        //     // CONTENT SUCCESS
        //   } else {
        //     this.process.print = false
        //     this.message.error = response.message
        //   }
        // })

        // window.open(`savoirprint://${process.env.API}public/order/print-detail?uuid=${this.detail.uuid}`)

        location.href = `savoirprint://${process.env.API}public/order/print-detail?uuid=${this.detail.uuid}`
      },

      async save_form_payment_proof_cek () {
        this.message.error = ''

        this.process.form_payment_proof_cek = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/order/checked`, {
          id: this.detail.id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form_payment_proof_cek = false

            this.dialog.payment_proof = false

            this.sheet.detail = false

            this.selected.menu = 3
            this.$refs.swiper.$swiper.slideTo(2, 500, false)

            this.fetch()
          } else {
            this.process.form_payment_proof_cek = false
            this.message.error = response.message
          }
        })
      },

      async fetchStore () {
        let params = {

        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.store = response.results.data
          }
        })
      }
    }
  }
</script>
