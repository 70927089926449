<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <!-- switch jadwal -->
        <v-dialog v-model="dialog.switch_jadwal"
          persistent
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Switch Jadwal
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second px-4 mt-3">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="py-0">
                  <!-- item -->
                  <v-row
                    style="height: calc(100vh - 17rem); overflow-y: auto;">
                    <v-col
                      v-for="(item, index) in detail.details"
                      :key="index"
                      cols="12">
                      <v-card
                        flat
                        color="#FFFFFF40"
                        class="pa-3 pt-2"
                        style="border: 2px dashed #bbb; border-radius: 10px;">
                        <v-list
                          flat
                          dense
                          three-line
                          color="transparent"
                          class="py-0">
                          <v-list-item
                            class="px-0">
                            <v-list-item-avatar
                              size="40"
                              tile
                              class="mt-3"
                              style="border: 2px solid #ffffff; border-radius: 5px !important;">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.item_image_url !== '' ? item.item_image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <div
                                class="mb-2">
                                <v-chip
                                  x-small
                                  color="#424242"
                                  class="white--text d-flex justify-center"
                                  style="width: 65px;">
                                  {{ item.type === 'service' ? 'Layanan' : item.type === 'product' ? 'Produk' : 'Bahan' }}
                                </v-chip>
                              </div>

                              <div
                                class="body-2 text-capitalize">
                                <div
                                  class="two-line font-weight-bold mb-1">
                                  {{ item.item_name }}
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Durasi. <strong>{{ item.duration_minutes }} Menit</strong>
                                  </div>

                                  <v-spacer />

                                  <div>
                                    Qty. <strong>{{ item.qty }}x</strong>
                                  </div>
                                </div>
                              </div>

                              <div v-if="item.visit.length > 0">
                                <v-divider
                                  class="my-3"
                                  style="border-top: 1px dashed #bbb;" />
                              </div>

                              <div v-if="item.visit.length > 0">
                                Klien
                              </div>

                              <v-row v-if="item.visit.length > 0">
                                <v-col
                                  v-for="(item_visit, index_visit) in item.visit"
                                  :key="index_visit"
                                  cols="12"
                                  class="py-2">
                                  <v-list
                                    flat
                                    dense
                                    three-line
                                    color="#FFFFFF40"
                                    class="border-radius"
                                    style="border: 1px dashed #bbb;">
                                    <v-list-item>
                                      <v-list-item-avatar
                                        size="40"
                                        class="mt-2"
                                        style="border: 2px solid #ffffff;">
                                        <v-img
                                          width="100%"
                                          height="100%"
                                          alt="bidanvitacare"
                                          :src="item_visit.client_image_url !== '' ? item_visit.client_image_url : require('@/assets/image/member_default.jpg')"
                                          class="ma-auto">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular
                                                size="20"
                                                indeterminate
                                                :color="set_color">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title
                                          class="body-2 font-weight-bold text-capitalize">
                                          {{ item_visit.client_name }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle
                                          class="body-2 font-weight-light line-text-second black--text"
                                          style="opacity: 70%;">
                                          <span v-if="item_visit.therapist_name !== ''"
                                            class="text-capitalize">
                                            Terapis. {{ item_visit.therapist_name }}
                                          </span>

                                          <span v-else>
                                            Terapis belum ditentukan
                                          </span>
                                        </v-list-item-subtitle>

                                        <v-list-item-subtitle
                                          class="body-2 font-weight-light line-text-second black--text">
                                          <v-divider
                                            class="my-2"
                                            style="border-top: 1px dashed #bbb;" />

                                          <div
                                            class="d-flex align-center">
                                            <div
                                              style="opacity: 70%;">
                                              Shift
                                            </div>

                                            <v-spacer />

                                            <div>
                                              {{ item_visit.therapist_shift !== '' ? item_visit.therapist_shift : '-' }}
                                            </div>
                                          </div>

                                          <div
                                            class="d-flex align-center">
                                            <div
                                              style="opacity: 70%;">
                                              Tanggal
                                            </div>

                                            <v-spacer />

                                            <div>
                                              <span v-if="item_visit.datetime !== '' && item_visit.datetime !== null">
                                                {{ item_visit.datetime | date }}
                                              </span>

                                              <span v-else>
                                                -
                                              </span>
                                            </div>
                                          </div>

                                          <div
                                            class="d-flex align-center">
                                            <div
                                              style="opacity: 70%;">
                                              Pukul
                                            </div>

                                            <v-spacer />

                                            <div>
                                              <span v-if="item_visit.datetime !== '' && item_visit.datetime !== null">
                                                {{ item_visit.datetime | time }}
                                              </span>

                                              <span v-else>
                                                -
                                              </span>
                                            </div>
                                          </div>

                                          <div
                                            class="d-flex align-center">
                                            <div
                                              style="opacity: 70%;">
                                              Toleransi
                                            </div>

                                            <v-spacer />

                                            <div>
                                              <span v-if="item_visit.toleransi_datetime !== '' && item_visit.toleransi_datetime !== null">
                                                {{ item_visit.toleransi_datetime | time }}
                                              </span>

                                              <span v-else>
                                                -
                                              </span>
                                            </div>
                                          </div>
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>

                        <v-divider
                          class="mb-2"
                          style="border-top: 1px dashed #bbb;" />

                        <div
                          class="my-3">
                          {{ item.note !== '' ? item.note : 'Tidak ada catatan' }}
                        </div>

                        <v-divider
                          class="my-2"
                          style="border-top: 1px dashed #bbb;" />

                        <!-- alat -->
                        <div
                          class="my-4">
                          <div
                            class="body-2 mb-2">
                            Alat yang harus dibawa terapis
                          </div>

                          <div
                            class="body-2 text-capitalize">
                            <ul v-if="item.material_json_array.length > 0"
                              style="position: relative; left: -10px;">
                              <li
                                v-for="(sub_item, sub_index) in item.material_json_array"
                                :key="sub_index"
                                :class="sub_index < item.material_json_array.length - 1 ? 'mb-1' : ''"
                                v-html="sub_item.name">
                              </li>
                            </ul>

                            <div v-else>
                              -
                            </div>
                          </div>
                        </div>

                        <div>
                          <div
                            class="body-2 mb-2">
                            Alat yang harus dipersiapkan customer
                          </div>

                          <div
                            class="body-2 text-capitalize">
                            <ul v-if="item.customer_prepare_json_array.length > 0"
                              style="position: relative; left: -10px;">
                              <li
                                v-for="(sub_item, sub_index) in item.customer_prepare_json_array"
                                :key="sub_index"
                                :class="sub_index < item.customer_prepare_json_array.length - 1 ? 'mb-1' : ''"
                                v-html="sub_item.value">
                              </li>
                            </ul>

                            <div v-else>
                              -
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  class="py-0">
                  <v-card
                    flat>
                    <div
                      class="mb-6">
                      <div class="body-2 mb-2">
                        Jadwal Layanan
                      </div>

                      <v-select
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Pilih Jadwal Layanan"

                        :items="detail.details"
                        item-value="id"
                        item-text="item_name"
                        :item-color="set_color"

                        hide-details

                        return-object

                        v-model="form.details_object">
                        <template v-slot:item="data">
                          <v-avatar
                            size="24">
                            <v-img
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="data.item.item_image_url !== '' ? data.item.item_image_url : require('@/assets/image/image_default.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <span
                            class="body-2 text-capitalize ml-2">
                            {{ data.item.item_name }}
                          </span>
                        </template>

                        <template v-slot:selection="data">
                          <v-avatar
                            size="24">
                            <v-img
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="data.item.item_image_url !== '' ? data.item.item_image_url : require('@/assets/image/image_default.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <span
                            class="body-2 text-capitalize ml-2">
                            {{ data.item.item_name }}
                          </span>
                        </template>
                      </v-select>

                      <div v-if="Object.keys(form.details_object).length > 0"
                        class="d-flex align-center mt-4 pa-3"
                        style="border: 1px dashed #bbb; border-radius: 10px;">
                        <div>
                          Durasi. <strong>{{ form.details_object.duration_minutes }} Menit</strong>
                        </div>

                        <v-spacer />

                        <div>
                          Qty. <strong>{{ form.details_object.qty }}</strong>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mb-6">
                      <div class="body-2 mb-2">
                        Jadwal 1
                      </div>

                      <v-select
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Pilih Jadwal 1"

                        :items="form.details_visit"
                        item-value="id"
                        item-text="therapist_name"
                        :item-color="set_color"

                        hide-details

                        return-object

                        @change="form.data2 = {}"

                        v-model="form.data1">
                        <template v-slot:item="data">
                          <v-avatar
                            size="24">
                            <v-img
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="data.item.therapist_image_url !== '' ? data.item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <span
                            class="body-2 text-capitalize ml-2">
                            {{ data.item.therapist_name }}
                          </span>
                        </template>

                        <template v-slot:selection="data">
                          <v-avatar
                            size="24">
                            <v-img
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="data.item.therapist_image_url !== '' ? data.item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <span
                            class="body-2 text-capitalize ml-2">
                            {{ data.item.therapist_name }}
                          </span>
                        </template>
                      </v-select>

                      <div v-if="Object.keys(form.data1).length > 0"
                        class="mt-4 pa-3 font-weight-light line-text-second black--text"
                        style="border: 1px dashed #bbb; border-radius: 10px;">
                        <div
                          class="d-flex align-center">
                          <div
                            style="opacity: 70%;">
                            Tanggal
                          </div>

                          <v-spacer />

                          <div>
                            {{ form.data1.datetime | date }}
                          </div>
                        </div>

                        <div
                          class="d-flex align-center">
                          <div
                            style="opacity: 70%;">
                            Pukul
                          </div>

                          <v-spacer />

                          <div>
                            {{ form.data1.datetime | time }}
                          </div>
                        </div>

                        <div
                          class="d-flex align-center">
                          <div
                            style="opacity: 70%;">
                            Toleransi
                          </div>

                          <v-spacer />

                          <div>
                            <span v-if="form.data1.toleransi_datetime !== '' && form.data1.toleransi_datetime !== null">
                              {{ form.data1.toleransi_datetime | time }}
                            </span>

                            <span v-else>
                              -
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mb-6">
                      <div class="body-2 mb-2">
                        Jadwal 2
                      </div>

                      <v-select
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Pilih Jadwal 2"

                        :items="form.details_visit"
                        item-value="id"
                        item-text="therapist_name"
                        :item-color="set_color"

                        hide-details

                        return-object

                        v-model="form.data2">
                        <template v-slot:item="data">
                          <v-avatar
                            size="24">
                            <v-img
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="data.item.therapist_image_url !== '' ? data.item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <span
                            class="body-2 text-capitalize ml-2">
                            {{ data.item.therapist_name }}
                          </span>
                        </template>

                        <template v-slot:selection="data">
                          <v-avatar
                            size="24">
                            <v-img
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="data.item.therapist_image_url !== '' ? data.item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <span
                            class="body-2 text-capitalize ml-2">
                            {{ data.item.therapist_name }}
                          </span>
                        </template>
                      </v-select>

                      <div v-if="Object.keys(form.data2).length > 0"
                        class="mt-4 pa-3 font-weight-light line-text-second black--text"
                        style="border: 1px dashed #bbb; border-radius: 10px;">
                        <div
                          class="d-flex align-center">
                          <div
                            style="opacity: 70%;">
                            Tanggal
                          </div>

                          <v-spacer />

                          <div>
                            {{ form.data2.datetime | date }}
                          </div>
                        </div>

                        <div
                          class="d-flex align-center">
                          <div
                            style="opacity: 70%;">
                            Pukul
                          </div>

                          <v-spacer />

                          <div>
                            {{ form.data2.datetime | time }}
                          </div>
                        </div>

                        <div
                          class="d-flex align-center">
                          <div
                            style="opacity: 70%;">
                            Toleransi
                          </div>

                          <v-spacer />

                          <div>
                            <span v-if="form.data2.toleransi_datetime !== '' && form.data2.toleransi_datetime !== null">
                              {{ form.data2.toleransi_datetime | time }}
                            </span>

                            <span v-else>
                              -
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>

              <div v-if="message.error.length > 0"
                class="text-center my-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="save_switch_jadwal()"
                :loading="process.form"
                :disabled="process.form || Object.keys(form.details_object).length < 1 || Object.keys(form.data1).length < 1 || Object.keys(form.data2).length < 1"

                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.switch_jadwal = false"
                :disabled="process.form"

                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- check available -->
        <v-dialog v-model="dialog.check_available"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Jadwal Bentrok
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text v-if="check_switch_available.length > 0"
              class="body-2 text-first line-text-second px-4 pb-2">
              <v-row>
                <v-col
                  v-for="(item, index) in check_switch_available"
                  :key="index"
                  cols="12">
                  <v-list
                    v-if="check_switch_available.length > 0"
                    flat
                    dense
                    three-line
                    color="#FFFFFF40"
                    class="border-radius"
                    style="border: 1px dashed #bbb;">
                    <v-list-item>
                      <v-list-item-avatar
                        size="40"
                        tile
                        class="mt-3"
                        style="border: 2px solid #ffffff; border-radius: 5px !important;">
                        <v-img
                          contain
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.item_image_url !== '' ? item.item_image_url : require('@/assets/image/image_default.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <div
                          class="body-2 font-weight-bold text-capitalize">
                          <span
                            class="two-line mb-1">
                            {{ item.item_name }}
                          </span>
                        </div>

                        <div
                          class="d-flex align-center">
                          <div>
                            Durasi. <strong>{{ item.item_duration_minutes }} Menit</strong>
                          </div>

                          <v-spacer />

                          <div>
                            Qty. <strong>{{ item.item_qty }}</strong>
                          </div>
                        </div>

                        <div>
                          <v-divider
                            class="my-3"
                            style="border-top: 1px dashed #bbb;" />
                        </div>

                        <!-- klien -->
                        <div
                          class="d-flex align-center">
                          <div>
                            Klien
                          </div>

                          <v-spacer />

                          <div
                            class="d-flex align-center">
                            <v-avatar
                              size="24"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.client_image_url !== '' ? item.client_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="caption font-weight-bold one-line text-capitalize ml-1">
                              {{ item.client_name }}
                            </span>
                          </div>
                        </div>

                        <div>
                          <v-divider
                            class="my-3"
                            style="border-top: 1px dashed #bbb;" />
                        </div>

                        <div>
                          <div
                            class="d-flex align-center font-weight-light mb-2">
                            <div
                              style="opacity: 70%;">
                              Tanggal
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.datetime !== '' && item.datetime !== null">
                                {{ item.datetime | date }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-center font-weight-light mb-2">
                            <div
                              style="opacity: 70%;">
                              Pukul
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.datetime !== '' && item.datetime !== null">
                                {{ item.datetime | time }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-center font-weight-light">
                            <div
                              style="opacity: 70%;">
                              Toleransi
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.toleransi_datetime !== '' && item.toleransi_datetime !== null">
                                {{ item.toleransi_datetime | time }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>
              <v-btn
                @click="dialog.check_available = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  Tutup
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="pb-0">
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                  class="pt-1 pb-0">
                  <v-btn
                    to="/jadwal/new/desktop/tambah"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                    <div
                      class="btn_auth_in d-flex align-center justify-center"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/add_plus.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="ml-2">
                        Tambah Jadwal
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="5"
                  class="pt-1 pb-0">
                  <v-menu
                    ref="date"
                    min-width="290px"
                    max-width="290px"
                    transition="scale-transition"
                    offset-y
                    :nudge-right="40"
                    :close-on-content-click="false"
                    v-model="picker.date">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        readonly
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Pilih Tanggal"

                        clearable
                        @click:clear="filter.date = ''; fetch();"

                        v-on="on"
                        v-bind="attrs"

                        hide-details

                        class="mb-4"

                        v-model="filter.date">
                      </v-text-field>
                    </template>

                    <v-date-picker
                      :color="set_color"
                      v-model="filter.date"
                      @input="picker.date = false; fetch();">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="5"
                  class="pt-1 pb-0">
                  <v-select
                    filled
                    rounded
                    dense
                    :color="set_color"
                    placeholder="Pilih Cabang"

                    :items="list_store"
                    item-value="id"
                    item-text="name"
                    :item-color="set_color"

                    clearable
                    @click:clear="filter.store_id = ''; fetch();"

                    @change="fetch();"

                    :no-filter="false"

                    v-model="filter.store_id">
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text
              class="pt-0">
              <v-row v-if="process.load"
                v-for="index in 3"
                :key="index">
                <v-col
                  cols="12"
                  md="2">
                  <v-skeleton-loader
                    loading
                    type="list-item-avatar-three-line">
                  </v-skeleton-loader>
                </v-col>

                <v-col
                  cols="12"
                  md="10">
                  <v-row>
                    <v-col
                      v-for="index in 6"
                      :key="index"
                      cols="12"
                      md="4"
                      class="py-0">
                      <v-skeleton-loader
                        loading
                        type="list-item-avatar-three-line">
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <div v-if="!process.load && list_data.length > 0"
                v-for="(item, index) in list_data"
                :key="index">
                <v-row>
                  <v-col
                    cols="12"
                    md="2">
                    <v-list
                      flat
                      dense
                      color="#FFFFFF40"
                      style="border: 2px dashed #bbb; border-radius: 10px;">
                      <v-list-item>
                        <v-list-item-avatar
                          size="40"
                          style="border: 2px solid #ffffff;">
                          <v-img
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="item.terapis.user_image_url !== '' ? item.terapis.user_image_url : require('@/assets/image/member_default.jpg')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize">
                            {{ item.terapis.user_name }}
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light black--text">
                            {{ item.terapis.store_name }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-col
                    cols="12"
                    md="10">
                    <v-row>
                      <v-col
                        v-for="(sub_item, sub_index) in item.data"
                        :key="sub_index"
                        cols="12"
                        md="4"
                        class="py-0"
                        :class="sub_index < item.data.length - 1 ? 'mb-6' : ''">
                        <div
                          class="d-flex align-center mb-1">
                          <div
                            class="font-weight-bold black--text">
                            {{ sub_item.datetime | time }} - {{ toMomentDuration(sub_item.datetime, sub_item.item_duration_minutes, sub_item.qty) }}
                          </div>

                          <v-spacer />

                          <div>
                            <v-icon
                              @click="dialog.switch_jadwal = true; fetchScheduleDetail(sub_item.schedule_id);"
                              small
                              color="green"
                              class="cursor-pointer mr-4">
                              mdi-swap-horizontal
                            </v-icon>
                          </div>

                          <div>
                            <router-link
                              :to="`/jadwal/new/desktop/ubah/${sub_item.schedule_id}`">
                              <v-icon
                                small
                                color="primary">
                                mdi-circle-edit-outline
                              </v-icon>
                            </router-link>
                          </div>
                        </div>

                        <v-card
                          flat
                          color="#FFFFFF40"
                          style="border: 2px dashed #bbb; border-radius: 10px;">
                          <v-card-text>
                            <div
                              class="d-flex align-center black--text mb-1">
                              <div
                                class="d-flex align-center">
                                <v-avatar
                                  size="22"
                                  style="border: 2px solid #ffffff;">
                                  <v-img
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="sub_item.customer_image_url !== '' ? sub_item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <span
                                  class="ml-1">
                                  {{ sub_item.customer_name }}
                                </span>
                              </div>

                              <v-spacer />

                              <div>
                                {{ sub_item.store_name }}
                              </div>
                            </div>

                            <div>
                              <v-divider
                                class="my-2"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div
                              class="mt-3 mb-2 pa-3 pt-2 black--text"
                              style="border: 2px dashed #bbb; border-radius: 10px;">
                              <v-chip
                                x-small
                                class="white--text"
                                :color="sub_item.type === 'on_site' ? 'blue' : 'green'"
                                style="font-weight: 500;">
                                {{ sub_item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                              </v-chip>

                              <div
                                class="d-flex align-start my-1">
                                <v-avatar
                                  tile
                                  size="22"
                                  style="border: 2px solid #ffffff;">
                                  <v-img
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="sub_item.item_image_url !== '' ? sub_item.item_image_url : require('@/assets/image/image_default.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <span
                                  class="ml-1 one-line">
                                  {{ sub_item.item_name }}
                                </span>
                              </div>

                              <div
                                style="margin-left: 26px;">
                                Durasi. <strong>{{ sub_item.item_duration_minutes }} Menit</strong>
                              </div>
                            </div>

                            <v-row>
                              <v-col
                                cols="12"
                                class="pt-1 pb-0">
                                <v-card
                                  flat
                                  color="#FFFFFF40"
                                  height="452px"
                                  class="pa-3"
                                  style="border: 2px dashed #bbb; border-radius: 10px; overflow-y: auto;">
                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      Terapis
                                    </div>

                                    <v-spacer />

                                    <div v-if="sub_item.therapist_name !== ''"
                                      class="d-flex align-center">
                                      <v-avatar
                                        size="22"
                                        style="border: 2px solid #ffffff;">
                                        <v-img
                                          width="100%"
                                          height="100%"
                                          alt="bidanvitacare"
                                          :src="sub_item.therapist_image_url !== '' ? sub_item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                                          class="ma-auto">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular
                                                size="20"
                                                indeterminate
                                                :color="set_color">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-avatar>

                                      <span
                                        class="ml-1">
                                        {{ sub_item.therapist_name }}
                                      </span>
                                    </div>

                                    <div v-else>
                                      -
                                    </div>
                                  </div>

                                  <div>
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      Shift
                                    </div>

                                    <v-spacer />

                                    <div>
                                      {{ sub_item.therapist_shift !== '' ? sub_item.therapist_shift : '-' }}
                                    </div>
                                  </div>

                                  <div>
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      Klien
                                    </div>

                                    <v-spacer />

                                    <div v-if="sub_item.client_name !== ''"
                                      class="d-flex align-center">
                                      <v-avatar
                                        size="22"
                                        style="border: 2px solid #ffffff;">
                                        <v-img
                                          width="100%"
                                          height="100%"
                                          alt="bidanvitacare"
                                          :src="sub_item.client_image_url !== '' ? sub_item.client_image_url : require('@/assets/image/member_default.jpg')"
                                          class="ma-auto">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular
                                                size="20"
                                                indeterminate
                                                :color="set_color">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-avatar>

                                      <span
                                        class="ml-1">
                                        {{ sub_item.client_name }}
                                      </span>
                                    </div>

                                    <div v-else>
                                      -
                                    </div>
                                  </div>

                                  <div v-if="sub_item.address_link_map !== ''">
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div v-if="sub_item.address_link_map !== ''"
                                    class="d-flex align-center">
                                    <div>
                                      Alamat
                                    </div>

                                    <v-spacer />

                                    <div>
                                      <a
                                        :href="sub_item.address_link_map"
                                        target="_blank">
                                        Lihat Lokasi
                                      </a>
                                    </div>
                                  </div>

                                  <div v-if="sub_item.type === 'home_care'">
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div v-if="sub_item.type === 'home_care'">
                                    <div v-if="sub_item.transport_id !== '' && sub_item.transport_id !== '0'"
                                      class="d-flex align-center">
                                      <div>
                                        Transport ({{ sub_item.transport_qty }} x {{ Number(sub_item.transport_cost) | price }})
                                      </div>

                                      <v-spacer />

                                      <div class="font-weight-bold">
                                        {{ Number(sub_item.transport_total_cost) | price }}
                                      </div>
                                    </div>

                                    <div v-else
                                      class="d-flex align-center">
                                      <div>
                                        Transport
                                      </div>

                                      <v-spacer />

                                      <div class="font-weight-bold">
                                        -
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      Penugasan
                                    </div>

                                    <v-spacer />

                                    <div class="text-uppercase font-weight-bold">
                                      {{ !sub_item.is_therapist_partner_bool ? 'Single' : 'Partner' }}
                                    </div>
                                  </div>

                                  <div>
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      Down Payment
                                    </div>

                                    <v-spacer />

                                    <div class="text-uppercase font-weight-bold">
                                      <span v-if="Number(sub_item.dp) > 0">
                                        {{ Number(sub_item.dp) | price }}
                                      </span>

                                      <span v-else>
                                        -
                                      </span>
                                    </div>
                                  </div>

                                  <div>
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      Diinput Oleh
                                    </div>

                                    <v-spacer />

                                    <div>
                                      {{ sub_item.create_user_name }}
                                    </div>
                                  </div>

                                  <div>
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div class="font-italic">
                                    <div class="mb-1">
                                      *Catatan Khusus Admin
                                    </div>

                                    <div>
                                      {{ sub_item.note_khusus !== '' ? sub_item.note_khusus : 'Tidak ada catatan khusus admin' }}
                                    </div>
                                  </div>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-divider
                  v-if="index < list_data.length - 1"
                  class="my-4"
                  style="border-top: 2px dashed #bbb;" />
              </div>

              <Empty v-if="!process.load && list_data.length < 1"
                class="py-16"
                margin="my-16"
                size="125"
                message="Jadwal" />
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from "moment"
  import "moment/locale/id"
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          switch_jadwal: false,
          check_available: false
        },

        search: '',

        filter: {
          date: moment().format('YYYY-MM-DD'),
          store_id: ''
        },

        detail: {},

        list_data: [],
        pagination_data: {},
        limit_data: 10,

        list_store: [],

        picker: {
          date: false
        },

        check_switch_available: [],

        form: {
          details_object: {},
          details_visit: [],

          data1: {},
          data2: {},

          check_data1: {},
          check_data2: {}
        },

        process: {
          load: false,
          form: false
        },

        message: {
          success: '',
          error: '',
          errors: []
        },

        // SEO
        content: {
          url: '/jadwal/new/desktop',
          title: 'Jadwal Desktop',
          description: 'Jadwal Desktop Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'form.details_object': function() {
        this.form.details_visit = this.detail.details.filter(x => x.id === this.form.details_object.id)[0]?.visit
      },

      'form.data1': function() {
        this.form.check_data1 = {
          id: this.form.data1.id,
          therapist_id: this.form.data1.therapist_id,
          start_datetime: this.form.data1.toleransi_datetime === '' || this.form.data1.toleransi_datetime === null ? this.form.data1.datetime : this.form.data1.toleransi_datetime,
          end_datetime: moment(this.form.data1.datetime).add(Number(this.form.details_object.duration_minutes) * Number(this.form.details_object.qty), 'minutes').format('YYYY-MM-DD HH:mm')
        }

        if (Object.keys(this.form.data2).length > 0) this.checkSwitchAvailable()
      },

      'form.data2': function() {
        this.form.check_data2 = {
          id: this.form.data2.id,
          therapist_id: this.form.data2.therapist_id,
          start_datetime: this.form.data2.toleransi_datetime === '' || this.form.data2.toleransi_datetime === null ? this.form.data2.datetime : this.form.data2.toleransi_datetime,
          end_datetime: moment(this.form.data2.datetime).add(Number(this.form.details_object.duration_minutes) * Number(this.form.details_object.qty), 'minutes').format('YYYY-MM-DD HH:mm')
        }

        if (Object.keys(this.form.data1).length > 0) this.checkSwitchAvailable()
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetchStore()

      this.fetch()
    },
    methods: {
      async fetch () {
        let params = {
          date: this.filter.date,

          store_id: this.filter.store_id
        }

        await this.$axios.$get(`${process.env.API}admin/schedule_v3/data_therapist_by_date`, { params })
        .then(async(response) => {

          if (response.status === 200) {
            this.list_data = []

            if (response.results.data.length > 0) {
              await Promise.all(response.results.data.map(async (item) => {
                await this.fetchSchedule(item)
              }))
            }
          }
        })
      },

      async fetchStore () {
        let params = {
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.list_store = response.results.data
          }
        })
      },

      async fetchSchedule (item) {
        this.process.load = true

        let params = {
          limit: 10000,

          therapist_id: item.user_id,

          datetime: this.filter.date,
          store_id: this.filter.store_id
        }

        await this.$axios.$get(`${process.env.API}admin/schedule_v3/data_visit`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list_data.push({
              terapis: item,
              data: response.results.data
            })
          }
        })
      },

      async fetchScheduleDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/schedule_v3/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },

      toMomentDuration (datetime, minutes, qty) {
        return moment(datetime).add(Number(minutes) * Number(qty), 'minutes').format('HH:mm')
      },

      // cek datetime available
      async checkSwitchAvailable () {
        await this.$axios.$post(`${process.env.API}admin/schedule_v3/check_datetime/switch_available`, {
          data1: this.form.check_data1,
          data2: this.form.check_data2
        })
        .then((response) => {
          if (response.status === 200) {
            this.check_switch_available = response.results.data.data1

            this.dialog.check_available = this.check_switch_available.length > 0 ? true : false
          }
        })
      },

      async save_switch_jadwal () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/schedule_v3/switch_visit`, {
          data1: {
            id: this.form.data1.id,
            therapist_id: this.form.data2.therapist_id,
            therapist_shift: this.form.data1.therapist_shift
          },
          data2: {
            id: this.form.data2.id,
            therapist_id: this.form.data1.therapist_id,
            therapist_shift: this.form.data2.therapist_shift
          }
        })
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {
            this.dialog.switch_jadwal = false

            this.form = {
              details_object: {},
              details_visit: [],

              data1: {},
              data2: {},

              check_data1: {},
              check_data2: {}
            }

            this.fetchScheduleDetail(this.detail.id)
            this.fetch()
          } else {
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>

<style>
  .jadwal-desktop-detail .v-dialog {
    min-width: 1000px !important;
    max-width: 1000px !important;
  }
</style>
