<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.rating"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Rating
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second text-center mt-4">
              <v-rating
                v-model="form.rating"
                dense
                size="45"
                color="yellow accent-4"
                background-color="#E0E0E0"
                empty-icon="$ratingFull">
              </v-rating>

              <div
                class="body-2 line-text-second text-center my-4">
                Silahkan masukkan rating Anda untuk pelayanan kami di Bidanvitacare.id
              </div>

              <div v-if="message.error.length > 0"
                class="text-center my-2 d-flex align-center justify-center">
                <div
                  class="error--text text-center">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="save"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="reset"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.no_phone"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  No Handphone customer tidak ditemukan,
                </div>

                <div>
                  cantumkan untuk share inovice!
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.no_phone = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.no_phone = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            height="100vh"
            class="transparent"
            style="overflow-y: auto;">
            <div
              id="nota">
              <v-card v-if="Object.keys(detail).length > 0"
                flat
                color="transparent">
                <v-card-text
                  class="text-center pt-0 mb-4">
                  <!-- <v-img
                    contain
                    width="200"
                    height="auto"
                    class="ma-auto"
                    alt="bidanvitacare"
                    :src="require('@/assets/logo/favicon.png')"
                    style="filter: grayscale(1) invert(1) !important;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img> -->

                  <v-img
                    contain
                    width="200"
                    height="auto"
                    class="ma-auto"
                    alt="bidanvitacare"
                    :src="detail.store.logo">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <div>
                    <div
                      class="mb-7">
                      <div>
                        Mom, Baby & Kids Spa Sejak 2017
                      </div>

                      <div
                        class="mt-2">
                        Sahabat Ceria Bunda & Buah Hati
                      </div>
                    </div>

                    <div
                      class="mb-4">
                      <div
                        class="font-weight-bold black--text mb-2">
                        {{ detail.store.name }}
                      </div>

                      <div>
                        {{ detail.store.address }}
                      </div>
                    </div>

                    <div>
                      Buka Setiap Hari {{ detail.store.open_hours }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card v-if="Object.keys(detail).length > 0"
                flat
                color="transparent">
                <v-card-text
                  class="pt-0 mb-2">
                  <div
                    class="text-center mb-6">
                    <div
                      class="font-weight-bold black--text">
                      Transaksi {{ detail.code }}
                    </div>

                    <div>
                      {{ detail.create_datetime | date }}
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 2px dashed #e0e0e0;" />

                  <div>
                    <div
                      class="d-flex align-center mb-4">
                      <div
                        class="font-weight-bold black--text">
                        {{ detail.customer_name }}
                      </div>

                      <v-spacer />

                      <div>
                        Kunjungan ke <strong class="black--text">{{ detail.so_count }}</strong>
                      </div>
                    </div>

                    <div
                      v-for="(item, index) in detail.items"
                      :key="index"
                      :class="index < detail.items.length - 1 ? 'mb-4' : ''"
                      class="px-3"
                      style="border: 2px dashed #e0e0e0; border-radius: 10px;">
                      <!-- <v-row>
                        <v-col
                          cols="8">
                          <strong>{{ item.qty }}x</strong> {{ item.name }}
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right">
                          {{ item.subtotal | price }}
                        </v-col>
                      </v-row> -->

                      <div
                        class="mt-2">
                        {{ item.name }}
                      </div>

                      <v-row>
                        <v-col
                          cols="8"
                          class="pb-1">
                          Harga
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right pb-1">
                          {{ item.price | price }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="8"
                          class="py-1">
                          Diskon <span v-if="item.disc_percent > 0">({{ item.disc_percent }}%)</span>
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right py-1">
                          {{ item.disc_value | price }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="8"
                          class="py-1">
                          Sub Total
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right py-1">
                          {{ item.nett_price | price }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="8"
                          class="py-1">
                          Qty
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right py-1">
                          {{ item.qty }}x
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="8"
                          class="font-weight-bold pt-1">
                          Total
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right font-weight-bold pt-1">
                          {{ item.subtotal | price }}
                        </v-col>
                      </v-row>

                      <v-divider v-if="item.customer.length > 0"
                        style="border-top: 2px dashed #e0e0e0;" />

                      <div v-if="item.customer.length > 0"
                        class="black--text font-weight-bold mt-4 mb-3">
                        Customer
                      </div>

                      <div>
                        <div
                          v-for="(item_customer, index_customer) in item.customer"
                          :key="index_customer"
                          class="mb-2">
                          <div
                            class="d-flex align-center">
                            <v-avatar
                              size="24"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto"
                                style="filter: grayscale(1) invert(0.1) !important;">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="caption font-weight-bold ml-1">
                              {{ item_customer.name }}
                            </span>
                          </div>
                        </div>
                      </div>


                      <v-divider v-if="item.visit.length > 0"
                        class="my-4"
                        style="border-top: 2px dashed #e0e0e0;" />

                      <div v-if="item.visit.length > 0"
                        class="black--text font-weight-bold mt-4 mb-3">
                        Terapis
                      </div>

                      <div
                        v-for="(item_visit, index_visit) in item.visit"
                        :key="index_visit"
                        :class="index_visit < item.visit.length - 1 ? 'mb-2' : 'mb-3'">
                        <div
                          class="d-flex align-center">
                          <v-avatar
                            size="24"
                            class="mt-0"
                            style="border: 2px solid #ffffff;">
                            <v-img
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="item_visit.user_image_url !== '' ? item_visit.user_image_url : require('@/assets/image/member_default.jpg')"
                              class="ma-auto"
                              style="filter: grayscale(1) invert(0.1) !important;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <span
                            class="caption font-weight-bold ml-1">
                            {{ item_visit.user_name }}
                          </span>
                        </div>
                      </div>

                      <v-divider v-if="item.advice.length > 0"
                        style="border-top: 2px dashed #e0e0e0;" />

                      <div v-if="item.advice.length > 0"
                        class="mb-3">
                        <div
                          class="black--text font-weight-bold mt-4 mb-2">
                          Advice
                        </div>

                        <div>
                          <span v-if="item.advice !== ''"
                            v-html="item.advice">
                          </span>

                          <span v-else>
                            -
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 2px dashed #e0e0e0;" />

                  <div>
                    <div
                      class="d-flex align-center mb-2">
                      <div>
                        Sub Total
                      </div>

                      <v-spacer />

                      <div>
                        {{ detail.total_item_subtotal | price }}
                      </div>
                    </div>

                    <div
                      class="d-flex align-center mb-2">
                      <div>
                        Down Payment
                      </div>

                      <v-spacer />

                      <div>
                        {{ detail.dp_paid | price }}
                      </div>
                    </div>

                    <div
                      class="d-flex align-center"
                      :class="detail.type === 'home_care' ? 'mb-2' : ''">
                      <div>
                        Diskon <span v-if="detail.discount_percent > 0">({{ detail.discount_percent }}%)</span>
                      </div>

                      <v-spacer />

                      <div>
                        {{ detail.discount_value | price }}
                      </div>
                    </div>

                    <div v-if="detail.type === 'home_care'"
                      class="d-flex align-center">
                      <div>
                        Transport
                      </div>

                      <v-spacer />

                      <div>
                        {{ detail.transport_cost | price }}
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    v-for="(item_payment, index_payment) in detail.payments"
                    :key="index_payment">
                    <div
                      class="d-flex align-start"
                      :class="item_payment.method !== 'cash' ? 'mt-4 mb-2' : 'my-4'">
                      <div>
                        Metode Pembayaran

                        <span v-if="detail.payments.length > 1">
                          {{ index_payment + 1 }}
                        </span>
                      </div>

                      <v-spacer />

                      <div>
                        <div
                          class="text-capitalize text-right">
                          {{ item_payment.method }}
                        </div>

                        <div
                          class="text-capitalize text-right">
                          {{ item_payment.amount | price }}
                        </div>
                      </div>
                    </div>

                    <div v-if="item_payment.method === 'transfer' || item_payment.method === 'edc'"
                      class="d-flex align-center mb-2">
                      <div>
                        Bank
                      </div>

                      <v-spacer />

                      <div
                        class="text-capitalize">
                        {{ item_payment.method_bank_name }}
                      </div>
                    </div>

                    <div v-if="item_payment.method !== 'cash'"
                      class="d-flex align-center mb-4">
                      <div>
                        Nomor Referensi
                      </div>

                      <v-spacer />

                      <div
                        class="text-capitalize">
                        {{ item_payment.method_ref }}
                      </div>
                    </div>

                    <v-divider
                      class="my-4"
                      style="border-top: 1px dashed #bbb;" />
                  </div>

                  <div>
                    <div
                      class="font-weight-bold black--text d-flex align-center mb-2">
                      <div>
                        Total
                      </div>

                      <v-spacer />

                      <div>
                        {{ detail.grand_total | price }}
                      </div>
                    </div>

                    <div
                      class="d-flex align-end">
                      <div>
                        <div
                          class="text-capitalize">
                          {{ detail.payment_method }}
                        </div>

                        <div>
                          {{ detail.complete_datetime | date }} Pukul {{ detail.complete_datetime | time }}
                        </div>
                      </div>

                      <v-spacer />

                      <div>
                        {{ detail.amount_paid | price }}
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 2px dashed #e0e0e0;" />

                  <div
                    class="d-flex align-center mb-2">
                    <div>
                      Kasir
                    </div>

                    <v-spacer />

                    <div>
                      {{ detail.cashier_name }}
                    </div>
                  </div>

                  <v-divider
                    v-if="detail.kpsp.length > 0"
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="detail.kpsp.length > 0"
                    class="mb-4">
                    <div
                      class="font-weight-bold black--text mb-2">
                      KPSP
                    </div>

                    <div
                      v-for="(item, index) in detail.kpsp"
                      :key="index">
                      <div
                        class="mb-2">
                        Petanyaan:
                      </div>

                      <div
                        class="line-text-second mb-2">
                        {{ item.customer_visit_kpsp_question }}
                      </div>

                      <div
                        class="mb-2">
                        Jawaban:
                      </div>

                      <div
                        class="black--text">
                        <strong :class="item.customer_visit_kpsp_answer === 'YA' ? 'green--text' : 'red--text'">{{ item.customer_visit_kpsp_answer }}</strong>
                      </div>

                      <v-divider
                        v-if="index < detail.kpsp.length - 1"
                        class="my-4"
                        style="border-top: 1px dashed #bbb;" />
                    </div>
                  </div>

                  <v-divider
                    v-if="detail.graph.bb.image !== '' || detail.graph.tb.image !== '' || (typeof detail.graph.lila === 'object' && detail.graph.lila.image !== '') || (typeof detail.graph.lk === 'object' && detail.graph.lk.image !== '')"
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="detail.graph.bb.image !== ''"
                    class="text-center mb-6">
                    <div>
                      <div
                        class="font-weight-bold black--text mb-2">
                        {{ detail.graph.bb.title }}
                      </div>
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail.graph.bb.image !== '' ? detail.graph.bb.image : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-2">
                        Kesimpulan
                      </div>

                      <div>
                        {{ detail.graph.bb.kesimpulan }}
                      </div>
                    </div>
                  </div>

                  <div v-if="detail.graph.tb.image !== ''"
                    class="text-center mb-6">
                    <div>
                      <div
                        class="font-weight-bold black--text mb-2">
                        {{ detail.graph.tb.title }}
                      </div>
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail.graph.tb.image !== '' ? detail.graph.tb.image : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-2">
                        Kesimpulan
                      </div>

                      <div>
                        {{ detail.graph.tb.kesimpulan }}
                      </div>
                    </div>
                  </div>

                  <div v-if="typeof detail.graph.lila === 'object' && detail.graph.lila.image !== ''"
                    class="text-center mb-6">
                    <div>
                      <div
                        class="font-weight-bold black--text mb-2">
                        {{ detail.graph.lila.title }}
                      </div>
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail.graph.lila.image !== '' ? detail.graph.lila.image : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-2">
                        Kesimpulan
                      </div>

                      <div>
                        {{ detail.graph.lila.kesimpulan }}
                      </div>
                    </div>
                  </div>

                  <div v-if="typeof detail.graph.lk === 'object' && detail.graph.lk.image !== ''"
                    class="text-center mb-6">
                    <div>
                      <div
                        class="font-weight-bold black--text mb-2">
                        {{ detail.graph.lk.title }}
                      </div>
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail.graph.lk.image !== '' ? detail.graph.lk.image : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-2">
                        Kesimpulan
                      </div>

                      <div>
                        {{ detail.graph.lk.kesimpulan }}
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="text-center mb-2">
                    <div>
                      <div
                        class="font-weight-bold black--text mb-2">
                        Nomor Rekening Bidanvitacare.id
                      </div>

                      <div
                        class="mb-2">
                        Scan QR Kode untuk melakukan pembayaran
                      </div>
                    </div>

                    <v-img
                      contain
                      width="300"
                      height="300"
                      alt="bidanvitacare"
                      :src="detail.qris_url"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      v-for="(item, index) in detail.banks"
                      :key="index"
                      class="mt-2">
                      No. Rekening - {{ item.label }}
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="text-center mb-2">
                    <div>
                      <div
                        class="font-weight-bold black--text mb-2">
                        Katalog Bidanvitacare.id
                      </div>

                      <div
                        class="mb-2">
                        Scan QR Kode untuk mengunjungi katalog kami
                      </div>
                    </div>

                    <v-img
                      contain
                      width="300"
                      height="300"
                      alt="bidanvitacare"
                      :src="detail.qrcode_katalog"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="text-center">
                    <div
                      class="font-weight-bold black--text mt-6 mb-2">
                      Sosial Media Kami
                    </div>

                    <div>
                      Facebook: bidanvitacare.id
                    </div>

                    <div>
                      Instagram: bidanvitacare.id
                    </div>

                    <div>
                      Twitter: bidanvitacare.id
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>

            <v-speed-dial v-if="Object.keys(detail).length > 0"
              v-model="fab"
              :bottom="true"
              :right="true"
              direction="top"
              :open-on-hover="false"
              transition="slide-y-reverse-transition"
              style="position: fixed;">
              <template v-slot:activator>
                <v-btn
                  :loading="process.download"
                  color="#ffe0df"
                  fab
                  elevation="0">
                  <v-icon v-if="fab"
                    :color="set_color">
                    mdi-close-thick
                  </v-icon>

                  <v-avatar v-else>
                    <v-img
                      contain
                      width="30"
                      height="30"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/share.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-btn>
              </template>

              <div
                v-for="(item_sosmed, index_sosmed) in sosmed"
                :key="index_sosmed">
                <ShareNetwork v-if="index_sosmed === 0 || index_sosmed === 1"
                  :network="item_sosmed.network"
                  :url="`https://bvc.amanata.co${$route.path}`"
                  :title="`Transaksi ${share.name}`"
                  :description="`Nota dari transaksi ${share.name}, total bayar sebesar ${share.price} pada tanggal ${share.datetime}.`"
                  hashtags="bidanvita.co,bvc.amanata.co">
                  <v-btn
                    fab
                    dark
                    small
                    :color="item_sosmed.color"
                    elevation="0">
                    <v-avatar>
                      <v-img
                        contain
                        width="30"
                        height="30"
                        alt="bidanvitacare"
                        :src="item_sosmed.icon"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                  </v-btn>
                </ShareNetwork>

                <div v-if="detail.customer_mobilephone !== ''">
                  <ShareNetwork v-if="index_sosmed === 2 || index_sosmed === 3"
                    :network="item_sosmed.network"
                    :url="`https://bvc.amanata.co${$route.path}`"
                    :title="`Transaksi ${share.name}`"
                    :description="`Nota dari transaksi ${share.name}, total bayar sebesar ${share.price} pada tanggal ${share.datetime}.`"
                    hashtags="bidanvita.co,bvc.amanata.co">
                    <v-btn
                      fab
                      dark
                      small
                      :color="item_sosmed.color"
                      elevation="0">
                      <v-avatar>
                        <v-img
                          contain
                          width="30"
                          height="30"
                          alt="bidanvitacare"
                          :src="item_sosmed.icon"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                    </v-btn>
                  </ShareNetwork>
                </div>

                <div v-else>
                  <v-btn v-if="index_sosmed === 2 || index_sosmed === 3"
                    @click="dialog.no_phone = true"
                    fab
                    dark
                    small
                    :color="item_sosmed.color"
                    elevation="0">
                    <v-avatar>
                      <v-img
                        contain
                        width="30"
                        height="30"
                        alt="bidanvitacare"
                        :src="item_sosmed.icon"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                  </v-btn>
                </div>

                <v-btn v-if="index_sosmed === 4"
                  @click="to_download"
                  :loading="process.download"
                  :disabled="process.download"
                  fab
                  dark
                  small
                  :color="item_sosmed.color"
                  elevation="0">
                  <v-avatar>
                    <v-img
                      contain
                      width="30"
                      height="30"
                      alt="bidanvitacare"
                      :src="item_sosmed.icon"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-btn>

                <!-- <v-btn v-if="index_sosmed === 5"
                  @click="reset()"
                  fab
                  dark
                  small
                  :color="item_sosmed.color"
                  elevation="0">
                  <v-avatar>
                    <v-img
                      contain
                      width="30"
                      height="30"
                      alt="bidanvitacare"
                      :src="item_sosmed.icon"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-btn> -->
              </div>
            </v-speed-dial>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  import moment from 'moment'
  moment.locale('id')

  export default {
    middleware: false,
    data () {
      return {
        fab: false,
        dialog: {
          rating: false,

          no_phone: false
        },
        sosmed: [
          { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg'), color: '#c2d8fe' },
          // { network: 'instagram', icon: require('@/assets/sosmed/ic_share_ig.svg'), color: '#fdc7de' },
          { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg'), color: '#c1effc' },
          { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg'), color: '#bfeefb' },
          { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg'), color: '#c1feda' },
          { network: 'download', icon: require('@/assets/icon/download.png'), color: '#c3cafd' },
          { network: 'rating', icon: require('@/assets/icon/rating.png'), color: '#fff8d2' }
        ],
        share: {
          name: '',
          price: 0,
          datetime: ''
        },
        detail: {},
        form: {
          uuid: '',
          rating: 0
        },
        process: {
          load: false,
          download: false,

          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/invoice/:slug',
          title: 'Invoice',
          description: 'Invoice Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {
      // 'detail.store.logo': function (val) {
      //   console.log(val)
      //   // this.getBase64Image(val)
      //   // .then(response => {
      //   //   console.log(response)
      //   // })
      // }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          uuid: this.$route.params.slug
        }

        await this.$axios.$get(`${process.env.API}public/order/detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.detail = response.results.data

            this.share = {
              name: this.detail.customer_name,
              price: this.format_price(this.detail.amount_paid),
              datetime: moment(this.detail.create_datetime).format('D MMM, YYYY HH:mm', 'id')
            }
          }
        })
      },

      format_price (num) {
        if (isNaN(num)) return 'Not a Number'

        let price = ''
        const reverseNumber = num
          .toString()
          .split('')
          .reverse()
          .join('')
        const arrReverseNumber = [...Array(reverseNumber.length).keys()]

        arrReverseNumber.map(index => {
          if (index % 3 === 0) price += reverseNumber.substr(index, 3) + '.'
        })

        return `Rp ${
          price.split('', price.length - 1)
          .reverse()
          .join('')
        }`
      },

      async to_download () {
        this.process.download = true

        let self = this,
            data = document.getElementById('nota')

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,

          // width: 1920,
          // height: 1080,
          // windowWidth: 7680,
          // windowHeight: 4320
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          // let img = canvas.toDataURL('image/png', 1.0), a = document.createElement('a')

          // a.href = img
          // a.download = `bidanvita.co-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.png`
          // a.click()

          // self.process.download = false

          // window.location.reload()

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.save_upload(img, response.data.fileuri)
            })
          })
        })
      },
      async save_upload (img, image_url) {
        this.message.error = ''

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/order/update-nota-image`, {
          id: this.detail.id,
          nota_image_url: image_url
        })
        .then((response) => {

          if (response.status === 200) {

            let a = document.createElement('a')

            a.href = img
            a.download = `bidanvita.co-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.png`
            a.click()

            this.process.download = false

            window.location.reload()
          } else {
            this.process.download = false
            this.message.error = response.message
          }
        })
      },

      reset () {
        this.dialog.rating = !this.dialog.rating ? true : false

        this.form = {
          uuid: this.$route.params.slug,
          rating: 0
        }

        this.process.form = false

        this.message.error = ''
      },

      async save () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}public/order/set-rating`, this.form)
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.rating = false

            this.fetch()

            if (this.form.rating > 4) window.open(`${response.results.data.google_review_link}`, '_blank')
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      // async getBase64Image (url) {
      //   const data = await fetch(url);
      //   const blob = await data.blob();
      //   return new Promise((resolve) => {
      //     const reader = new FileReader();
      //     reader.readAsDataURL(blob);
      //     reader.onloadend = () => {
      //       const base64data = reader.result;
      //       resolve(base64data);
      //     }
      //   });
      // }
    }
  }
</script>
