<template>
  <div class="fill-height pt-10">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="intro"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Visi & Misi
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="my-2 px-4 pb-2">
              <div
                class="mb-4">
                <div
                  class="body-2 font-weight-bold mb-2">
                  Visi
                </div>

                <div
                  class="line-text-second">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 1 : 1"
                    :key="index"
                    :loading="process.load"
                    type="article">
                    <template>
                      <div>
                        <div v-if="!process.load"
                          v-html="config.visi">
                        </div>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="body-2 font-weight-bold mb-2">
                  Misi
                </div>

                <div
                  class="ul_config">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 1 : 1"
                    :key="index"
                    :loading="process.load"
                    type="article, list-item-three-line">
                    <template>
                      <div>
                        <div v-if="!process.load"
                          v-html="config.misi">
                        </div>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </div>
              </div>

              <div>
                <div
                  class="body-2 font-weight-bold mb-2">
                  Value
                </div>

                <div
                  class="ul_config">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 1 : 1"
                    :key="index"
                    :loading="process.load"
                    type="article, list-item-three-line">
                    <template>
                      <div>
                        <div v-if="!process.load"
                          v-html="config.value">
                        </div>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="introductions()"
                rounded
                elevation="0"
                class="body-2 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="16">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Tutup
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="text-center pb-0">
              <v-img
                contain
                width="200"
                height="auto"
                class="ma-auto"
                alt="bidanvitacare"
                :src="require('@/assets/logo/favicon.png')">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                style="position: relative; top: -35px;">
                <div>
                  Mom, Baby & Kids Spa Sejak 2017
                </div>

                <div
                  class="mt-2">
                  Sahabat Ceria Bunda & Buah Hati
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="transparent">
            <v-card-text>
              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-1 font-weight-bold">
                  Menu Bidanvitacare.id
                </div>

                <v-spacer />

                <div>
                  <v-chip
                    small
                    :color="set_color"
                    class="white--text font-weight-bold text-capitalize">
                    {{ user.role }}
                  </v-chip>
                </div>
              </div>

              <v-row>
                <v-col
                  v-for="(item, index) in menu"
                  :key="index"
                  cols="4">
                  <v-card
                    @click="reset(item)"
                    color="#FFFFFF40"
                    class="border-radius"
                    style="box-shadow: rgb(244, 67, 54, 0.2) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;">
                    <v-card-text
                      class="px-2">
                      <v-img
                        width="40"
                        height="40"
                        alt="bidanvitacare"
                        :src="item.icon"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <div
                        class="caption text-center two-line mt-3"
                        style="height: 40px;">
                        {{ item.text }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="transparent">
            <v-card-text>
              <div
                class="body-1 font-weight-bold mb-4">
                Pengaturan Akun
              </div>

              <v-row>
                <v-col
                  v-for="(item, index) in setting"
                  :key="index"
                  cols="4">
                  <v-card
                    @click="goTo(item.link)"
                    color="#FFFFFF40"
                    class="border-radius"
                    style="box-shadow: rgb(244, 67, 54, 0.2) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;">
                    <v-card-text>
                      <v-img
                        width="40"
                        height="40"
                        alt="bidanvitacare"
                        :src="item.icon"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <div
                        class="caption text-center two-line mt-3"
                        style="height: 40px;">
                        {{ item.text }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        menu: [],
        setting: [],
        config: {
          visi: '',
          misi: '',
          value: ''
        },
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/home',
          title: 'Beranda',
          description: 'Beranda Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },

      intro () {
        return this.$store.state.intro.intro
      }
    },
    mounted () {
      this.fetch()
      this.check_menu()
    },
    methods: {
      fetch () {
        this.fetchConfigVisi()
        this.fetchConfigMisi()
        this.fetchConfigValue()
      },
      async fetchConfigVisi () {
        this.process.load = true

        let params = {
          key: 'visi'
        }

        await this.$axios.$get(`${process.env.API}config`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.config.visi = response.results.data[0].value
          }
        })
      },
      async fetchConfigMisi () {
        this.process.load = true

        let params = {
          key: 'misi'
        }

        await this.$axios.$get(`${process.env.API}config`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.config.misi = response.results.data[0].value
          }
        })
      },
      async fetchConfigValue () {
        this.process.load = true

        let params = {
          key: 'value'
        }

        await this.$axios.$get(`${process.env.API}config`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.config.value = response.results.data[0].value
          }
        })
      },

      check_menu () {
        if (this.user.role !== 'therapist') {
          if (this.user.role === 'superadmin') {
            this.menu = [
              {
                text: 'Dashboard',
                icon: require('@/assets/image/footer/dashboard.png'),
                link: '/dashboard'
              },
              {
                text: 'Kasir',
                icon: require('@/assets/image/footer/kasir.png'),
                link: '/kasir'
              },
              {
                text: 'Transaksi',
                icon: require('@/assets/image/footer/transaction.png'),
                link: '/transaksi'
              },
              {
                text: 'Saldo',
                icon: require('@/assets/image/footer/saldo.png'),
                link: '/saldo'
              },
              {
                text: 'Laporan',
                icon: require('@/assets/image/footer/report.png'),
                link: '/laporan'
              },
              {
                text: 'Rekam Kunjungan',
                icon: require('@/assets/image/footer/visit.png'),
                link: '/kunjungan/new'
              },
              {
                text: 'Jadwal Treatment',
                icon: require('@/assets/image/footer/jadwal.png'),
                link: '/jadwal/new'
              },
              {
                text: 'Pelanggan',
                icon: require('@/assets/image/footer/pelanggan_bvc.png'),
                link: '/pelanggan'
              },
              {
                text: 'Pegawai',
                icon: require('@/assets/image/footer/nurse_bvc.png'),
                link: '/pegawai'
              },
              {
                text: 'Layanan',
                icon: require('@/assets/image/footer/baby_bvc.png'),
                link: '/layanan'
              },
              {
                text: 'Produk',
                icon: require('@/assets/image/footer/catalog.png'),
                link: '/produk'
              },
              {
                text: 'Bahan & Alat',
                icon: require('@/assets/image/footer/material.png'),
                link: '/bahan'
              },
              {
                text: 'Pinjam Barang',
                icon: require('@/assets/image/footer/loan.png'),
                link: '/pinjam'
              },
              {
                text: 'Panduan',
                icon: require('@/assets/image/footer/guide.png'),
                link: '/panduan'
              },
              {
                text: 'Lokasi',
                icon: require('@/assets/image/footer/store.png'),
                link: '/toko'
              },
              {
                text: 'Titipan',
                icon: require('@/assets/image/footer/titipan.png'),
                link: '/titipan'
              },
              {
                text: 'Klien Rutin',
                icon: require('@/assets/image/footer/client_rutin.png'),
                link: '/rutin'
              },
              {
                text: 'Shift',
                icon: require('@/assets/image/footer/shift.png'),
                link: '/shift'
              },
              {
                text: 'Bank',
                icon: require('@/assets/image/footer/bank.png'),
                link: '/bank'
              }
            ]

            this.setting = [
              {
                text: 'Profil Saya',
                icon: require('@/assets/image/footer/user_bvc.png'),
                link: '/profil'
              },
              {
                text: 'Konfig Master',
                icon: require('@/assets/image/footer/settings.png'),
                link: '/konfigurasi'
              },
              {
                text: 'Jadwal Lama',
                icon: require('@/assets/image/footer/jadwal_lama.png'),
                link: '/jadwal'
              },
              {
                text: 'RK. Lama',
                icon: require('@/assets/image/footer/rk_lama.png'),
                link: '/kunjungan'
              },
              {
                text: 'Keluar',
                icon: require('@/assets/image/profile/logout.png'),
                link: '/logout'
              }
            ]
          } else {
            this.menu = [
              {
                text: 'Dashboard',
                icon: require('@/assets/image/footer/dashboard.png'),
                link: '/dashboard'
              },
              {
                text: 'Kasir',
                icon: require('@/assets/image/footer/kasir.png'),
                link: '/kasir'
              },
              {
                text: 'Transaksi',
                icon: require('@/assets/image/footer/transaction.png'),
                link: '/transaksi'
              },
              {
                text: 'Saldo',
                icon: require('@/assets/image/footer/saldo.png'),
                link: '/saldo'
              },
              {
                text: 'Rekam Kunjungan',
                icon: require('@/assets/image/footer/visit.png'),
                link: '/kunjungan/new'
              },
              {
                text: 'Jadwal Treatment',
                icon: require('@/assets/image/footer/jadwal.png'),
                link: '/jadwal/new'
              },
              {
                text: 'Pelanggan',
                icon: require('@/assets/image/footer/pelanggan_bvc.png'),
                link: '/pelanggan'
              },
              {
                text: 'Layanan',
                icon: require('@/assets/image/footer/baby_bvc.png'),
                link: '/layanan'
              },
              {
                text: 'Produk',
                icon: require('@/assets/image/footer/catalog.png'),
                link: '/produk'
              },
              {
                text: 'Bahan & Alat',
                icon: require('@/assets/image/footer/material.png'),
                link: '/bahan'
              },
              {
                text: 'Pinjam Barang',
                icon: require('@/assets/image/footer/loan.png'),
                link: '/pinjam'
              },
              {
                text: 'Pengajuan Pinjam Barang',
                icon: require('@/assets/image/footer/pengajuan_pinjam.png'),
                link: '/pengajuan'
              },
              {
                text: 'Panduan',
                icon: require('@/assets/image/footer/guide.png'),
                link: '/panduan'
              },
              {
                text: 'Titipan',
                icon: require('@/assets/image/footer/titipan.png'),
                link: '/titipan'
              },
              {
                text: 'Klien Rutin',
                icon: require('@/assets/image/footer/client_rutin.png'),
                link: '/rutin'
              },
              {
                text: 'Absensi',
                icon: require('@/assets/image/footer/fingerprint.png'),
                link: '/absen'
              }
            ]

            this.setting = [
              {
                text: 'Profil Saya',
                icon: require('@/assets/image/footer/user_bvc.png'),
                link: '/profil'
              },
              {
                text: 'Jadwal Lama',
                icon: require('@/assets/image/footer/jadwal_lama.png'),
                link: '/jadwal'
              },
              {
                text: 'RK. Lama',
                icon: require('@/assets/image/footer/rk_lama.png'),
                link: '/kunjungan'
              },
              {
                text: 'Keluar',
                icon: require('@/assets/image/profile/logout.png'),
                link: '/logout'
              }
            ]
          }
        } else {
          this.menu = [
            {
              text: 'Penjadwalan',
              icon: require('@/assets/image/footer/kunjungan_pending.png'),
              link: '/penjadwalan/new'
            },
            {
              text: 'Absensi',
              icon: require('@/assets/image/footer/fingerprint.png'),
              link: '/absen'
            },
            {
              text: 'Pinjam Barang',
              icon: require('@/assets/image/footer/loan.png'),
              link: '/pinjam'
            },
            {
              text: 'Panduan',
              icon: require('@/assets/image/footer/guide.png'),
              link: '/panduan'
            },
            {
              text: 'Titipan',
              icon: require('@/assets/image/footer/titipan.png'),
              link: '/titipan'
            }
          ]

          this.setting = [
            {
              text: 'Profil Saya',
              icon: require('@/assets/image/footer/user_bvc.png'),
              link: '/profil'
            },
            {
              text: 'Penjadwalan Lama',
              icon: require('@/assets/image/footer/jadwal_lama.png'),
              link: '/penjadwalan'
            },
            {
              text: 'Keluar',
              icon: require('@/assets/image/profile/logout.png'),
              link: '/logout'
            }
          ]
        }

      },
      reset (item) {
        if (item.link === '/kasir') {
          let form = {
            id: '',
            type: 'on_site',
            customer_id: '',
            customer_address_id: '',
            note: '',
            items: [],
            discount_percent: 0,
            discount_value: 0,
            tax_percent: 0,
            tax_value: 0,

            transport_id: '',
            transport_name: '',
            transport_cost: 0,
            transport_qty: 1,
            transport_total_cost: 0,

            dp_paid: 0,

            amount_paid_origin: 0,
            amount_paid_sum: 0,
            amount_paid: 0,
            status: 'complete',

            payment: [],

            switch_saldo: false,

            total_days: 0,

            // KASIR
            cashier_id: 0,
            cashier_name: '',

            // DOWN PAYMENT
            switch_dp: false,

            dp: 0,
            dp_payment_method: '',
            dp_payment_method_bank_name: '',
            dp_payment_method_ref: ''
          }

          this.$store.commit('kasir/setKasir', form)
          Cookie.set('kasir', form)

          this.$store.commit('kasir/setPelanggan', {})
          Cookie.set('pelanggan', {})

          this.$store.commit('kasir/setAddress', {})
          Cookie.set('address', {})

          this.$store.commit('kasir/setPayment', {})
          Cookie.set('payment', {})
        }

        this.$router.push({ path: item.link })
      },

      introductions () {
        this.$store.commit('intro/setIntro', false)

        Cookie.set('intro', false)
      },

      async goTo (item) {
        if (item === '/logout') {
          await this.$axios.$post(`${process.env.API}auth/logout`, {
            username: this.user.username
          })
          .then((response) => {
            if (response.status === 200) {
              Cookie.remove('token')
              Cookie.remove('user')
              Cookie.remove('intro')
              Cookie.remove('fcm')
              Cookie.remove('notif')
              Cookie.remove('kasir')
              Cookie.remove('pelanggan')
              Cookie.remove('address')
              Cookie.remove('payment')

              window.location = '/auth/login'
            }
          })
        } else {
          this.$router.push({ path: item })
        }
      }
    }
  }
</script>

<style>
  .ul_config ul {
    position: relative; left: -10px;
    line-height: 1.75 !important;
  }
</style>
