<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.deletes"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/hapus_info.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin menghapus data
                </div>

                <div>
                  <strong>{{ detail.name }}</strong>?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="deletes()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.deletes = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.deletes_category"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/hapus_info.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin menghapus data
                </div>

                <div>
                  <strong>{{ selected.category.name }}</strong>?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="deletes_category()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.deletes_category = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <!-- <div>
              <div v-if="process.load || list.length > 0"
                class="body-2 font-weight-bold px-4 my-2">
                Layanan yang sering digunakan
              </div>

              <no-ssr>
                <swiper
                  class="swiper"
                  ref="swiper"
                  :options="{ slidesPerView: 'auto' }">
                  <swiper-slide
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    class="py-3 loader_produk"
                    :class="index === 0 ? 'pl-4 pr-4' : index < list.length - 1 ? 'pr-4' : 'pr-4'"
                    style="max-width: 75px;">
                    <v-skeleton-loader
                      :loading="process.load"
                      type="image">
                      <div>
                        <v-card v-if="!process.load && list.length > 0"
                          flat
                          color="transparent"
                          class="border-radius">
                          <v-img
                            contain
                            width="75"
                            height="75"
                            alt="bidanvitacare"
                            :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                            class="ma-auto border-radius"
                            style="border: 2px solid #ffffff; border-radius: 10px !important;">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </swiper-slide>
                </swiper>
              </no-ssr>
            </div> -->

            <v-card-text
              :class="process.load || list.length > 0 ? 'mt-1' : ''">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-btn
                @click="sheet.form = true; reset();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <div>
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/add_plus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Layanan
                  </div>
                </div>
              </v-btn>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <div
                class="body-2 font-weight-bold black--text d-flex align-center mb-2">
                <div>
                  Katalog Layanan
                </div>

                <v-spacer />

                <div>
                  <!-- <v-icon>mdi-filter-variant</v-icon> -->
                </div>
              </div>

              <v-row>
                <v-col>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          three-line
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item
                            @click="sheet.detail = true; fetchDetail(item.id);">
                            <v-list-item-avatar
                              size="40"
                              tile
                              class="mt-2"
                              style="border: 2px solid #ffffff; border-radius: 5px !important;">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <div>
                                <v-chip
                                  x-small
                                  class="caption white--text text-capitalize"
                                  :color="set_color"
                                  style="margin-bottom: 5px; max-width: 250px;">
                                  <span
                                    class="one-line d-flex align-center">
                                    <span v-if="item.category_par_name !== ''"
                                      class="d-flex align-center">
                                      {{ item.category_par_name }}

                                      <v-icon small style="margin-right: 2px !important; margin-left: 2px !important;">mdi-arrow-right-thin</v-icon>
                                    </span>

                                    {{ item.category_name !== '' ? item.category_name : '-' }}
                                  </span>
                                </v-chip>
                              </div>

                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text"
                                style="opacity: 70%;">
                                Durasi. {{ item.duration_minutes }} Menit
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2">
                                <span
                                  :class="item.special_price > 0 ? 'text-decoration-line-through red--text' : 'font-weight-bold'">
                                  {{ item.retail_price | price }}
                                </span>

                                <span v-if="item.special_price > 0"
                                  class="font-weight-bold ml-2">
                                  {{ item.special_price | price }}
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Layanan" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.detail = false; detail = {}; fetch();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.detail = false; detail = {}; fetch();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Layanan {{ detail.name }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 80px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <v-avatar
                    tile
                    size="150">
                    <v-img
                      width="100%"
                      height="100%"
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
                            border: 5px solid #0000000d;
                            border-radius: 10px;"
                      alt="bidanvitacare"
                      :src="detail.image_url !== '' ? detail.image_url : require('@/assets/image/image_default.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-card-text>

                <v-card-text>
                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Nama Layanan
                    </div>

                    <div
                      class="body-2 font-weight-bold black--text">
                      {{ detail.name }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Kategori
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.category_name !== '' ? detail.category_name : '-' }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Durasi
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.duration_minutes }} Menit
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Harga Jual
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.retail_price | price }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Harga Diskon
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.special_price | price }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Advice
                    </div>

                    <div
                      class="body-2 black--text">
                      <span v-if="detail.advice !== ''"
                        v-html="detail.advice">
                      </span>

                      <span v-else>
                        -
                      </span>
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Alat yang harus dibawa disiapkan terapis
                    </div>

                    <div
                      class="body-2 black--text">
                      <ul v-if="detail.material_json_array.length > 0"
                        style="position: relative; left: -10px;">
                        <li
                          v-for="(item, index) in detail.material_json_array"
                          :key="index"
                          :class="index < detail.material_json_array.length - 1 ? 'mb-1' : ''"
                          v-html="item.name">
                        </li>
                      </ul>

                      <span v-else>
                        -
                      </span>
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Alat yang di persiapkan Customer jika Home Care
                    </div>

                    <div
                      class="body-2 black--text">
                      <ul v-if="detail.customer_prepare_json_array.length > 0"
                        style="position: relative; left: -10px;">
                        <li
                          v-for="(item, index) in detail.customer_prepare_json_array"
                          :key="index"
                          :class="index < detail.customer_prepare_json_array.length - 1 ? 'mb-1' : ''"
                          v-html="item.value">
                        </li>
                      </ul>

                      <span v-else>
                        -
                      </span>
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Dibuat Tanggal
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.create_datetime | date }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="6"
                      class="py-0">
                      <v-btn
                        @click="sheet.form = true; reset(detail);"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/edit_pencil.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="blue--text ml-2">
                            Ubah
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="py-0">
                      <v-btn
                        @click="dialog.deletes = true"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/hapus.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="red--text ml-2">
                            Hapus
                          </div>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.form"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form = false; reset();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form = false; reset();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ form.id === '' ? 'Tambah' : 'Ubah' }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="d-flex align-center pt-16 mt-4">
                  <v-avatar
                    tile
                    size="150">
                    <v-img
                      width="100%"
                      height="100%"
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
                            border: 5px solid #0000000d;
                            border-radius: 10px;"
                      alt="bidanvitacare"
                      :src="form.image_url !== '' ? form.image_url : require('@/assets/image/image_default.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div>
                    <upload-folder
                      v-model="upload.folder">
                      <div slot="activator">
                        <v-btn
                          :disabled="process.file"
                          :loading="process.file"
                          elevation="0"
                          rounded
                          block
                          large
                          color="#0000000d"
                          class="body-1 font-weight-bold text-capitalize d-flex align-center"
                          style="border-radius: 0px 50px 50px 0px;"
                          :style="`color: ${set_color};`">
                          <v-icon
                            size="24"
                            :color="set_color">
                            mdi-camera
                          </v-icon>

                          <span
                            class="ml-1">
                            Gambar
                          </span>
                        </v-btn>
                      </div>
                    </upload-folder>
                  </div>
                </v-card-text>

                <v-card-text>
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <ValidationProvider
                        name="Nama Layanan"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Nama Layanan
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Tambahan Waktu Pijat"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.name"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <!-- <ValidationProvider
                        name="Kategori"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div
                          class="d-flex align-center">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            Kategori
                          </div>

                          <v-spacer />

                          <div
                            @click="sheet.delete_category = true; fetchCategory();"
                            class="body-2 font-weight-bold red--text cursor-pointer">
                            Hapus
                          </div>
                        </div>

                        <v-select
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Pijat Bayi"

                          :items="category"
                          item-text="name"
                          item-value="id"
                          :item-color="set_color"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.category_id"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider> -->

                      <div>
                        <div
                          class="d-flex align-center">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            Kategori / Sub Kategori
                          </div>

                          <v-spacer />

                          <div
                            @click="sheet.delete_category = true; fetchCategory();"
                            class="body-2 font-weight-bold red--text cursor-pointer">
                            Hapus
                          </div>
                        </div>

                        <v-menu
                          ref="selectprogram"
                          min-width="380px"
                          max-width="380px"
                          transition="scale-transition"
                          offset-y
                          :close-on-content-click="false"
                          v-model="select_category">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Program / Kegiatan / Sub Kegiatan"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save()"

                                readonly
                                filled
                                rounded
                                dense

                                :color="set_color"
                                placeholder="Contoh. Pijat Bayi"

                                clearable
                                @click:clear="form.category_id = ''; form.category_name = '';"

                                v-on="on"
                                v-bind="attrs"

                                v-model="form.category_name"
                                :success="form.category_name !== '' && valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-list
                            style="height: 300px; overflow-y: auto;">
                            <v-list-item
                              class="px-2">
                              <v-treeview
                                dense
                                open-on-click

                                activatable
                                :color="set_color"

                                expand-icon
                                rounded
                                transition

                                :items="category_tree"
                                item-key="id"
                                item-text="name"

                                item-children="children"

                                return-object
                                @update:active="to_value"

                                class="custom_treeview">
                                <template v-slot:prepend="{ item, open }">
                                  <v-icon v-if="item.children">
                                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                                  </v-icon>

                                  <v-icon v-else
                                    small>
                                    mdi-rhombus-split
                                  </v-icon>
                                </template>
                              </v-treeview>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>

                      <div
                        class="body-2 font-italic line-text-second mb-4">
                        #Jika kategori yang Anda cari tidak ditemukan,

                        <strong
                          @click="sheet.form_category = true; reset_category();"
                          class="blue--text cursor-pointer">
                          tambah disini
                        </strong>.
                      </div>

                      <ValidationProvider v-if="form.type === 'service'"
                        name="Durasi Layanan /menit"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Durasi Layanan /menit
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 30"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.duration_minutes"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Harga Jual"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Harga Jual
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 50.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.retail_price"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Harga Diskon"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Harga Diskon
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 25.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.special_price"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <!-- <ValidationProvider
                        name="Advice"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          Advice
                          <span>
                            (boleh dikosongi)
                          </span>
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan advice disini . . ."
                          :append-icon="form.advice !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.advice"
                          :success="form.advice !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider> -->

                      <div>
                        <div class="body-2 mb-2">
                          Advice
                        </div>

                        <quill-editor
                          ref="myTextEditor"
                          class="mb-6"
                          :options="{
                            placeholder: 'Tuliskan advice disini . . .'
                          }"
                          v-model="form.advice">
                        </quill-editor>
                      </div>

                      <ValidationProvider
                        name="Alat yang harus dibawa disiapkan terapis"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <!-- <span class="red--text">*</span> -->
                          Alat yang harus dibawa
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Diffuser"

                          :items="material"
                          item-text="name"
                          item-value="id"
                          :item-color="set_color"
                          :append-icon="form.material.length > 0 && valid ? 'mdi-check-circle-outline' : ''"

                          multiple
                          chips

                          return-object

                          v-model="form.material"
                          :success="form.material.length > 0 && valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <div>
                        <div
                          class="body-2 mb-3">
                          Alat yang di persiapkan Customer jika Home Care
                        </div>

                        <v-btn
                          @click="add_customer_prepare()"
                          rounded
                          color="#444444"
                          elevation="0"
                          class="text-capitalize white--text mb-4">
                          <v-icon
                            size="20"
                            color="white">
                            mdi-plus
                          </v-icon>

                          <span
                            class="ml-1">
                            Tambah Alat
                          </span>
                        </v-btn>

                        <div
                          v-for="(item_customer, index) in form.customer_prepare"
                          :key="index">
                          <div class="body-2">
                            <span class="red--text">*</span>
                            Alat {{ index + 1 }}
                            <!-- <span class="error--text">
                              (wajib diisi)
                            </span> -->
                          </div>

                          <v-row>
                            <v-col
                              cols="10"
                              class="pb-0 pr-0">
                              <ValidationProvider
                                :name="`Alat ${index + 1}`"
                                rules="required"
                                v-slot="{ errors, valid }">
                                <v-text-field
                                  v-on:keyup.enter="save"
                                  filled
                                  rounded
                                  dense
                                  :color="set_color"
                                  :placeholder="`Contoh. Alat ${index + 1}`"
                                  :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                  v-model="item_customer.value"
                                  :success="valid"
                                  :error-messages="errors"
                                  required>
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>

                            <v-col
                              cols="2"
                              class="pb-0 text-center">
                              <v-btn
                                fab
                                width="36"
                                height="36"
                                color="red"
                                elevation="0">
                                <v-icon
                                  @click="form.customer_prepare.splice(index, 1)"
                                  size="24"
                                  color="white">
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/image/footer/baby_bvc.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data untuk membuat layanan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form = false; reset();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.form_category"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form_category = false; reset_category();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form_category = false; reset_category();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ form_category.id === '' ? 'Tambah' : 'Ubah' }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <ValidationObserver
                    ref="form_category">
                    <v-form>
                      <ValidationProvider
                        name="Tipe"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Tipe
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save_category"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Kategori"

                          :items="menu_category"
                          :item-color="set_color"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @change="form_category.par_id = 0"

                          v-model="form_category.type"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <ValidationProvider v-if="form_category.type === 'Sub Kategori'"
                        name="Kategori"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Kategori
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save_category"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Pijat Bayi"

                          :items="category_parent"
                          item-text="name"
                          item-value="id"
                          :item-color="set_color"
                          :append-icon="valid && form_category.par_id !== 0 ? 'mdi-check-circle-outline' : ''"

                          v-model="form_category.par_id"
                          :success="valid && form_category.par_id !== 0"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Nama Kategori"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Nama {{ form_category.type === 'Sub Kategori' ? 'Sub' : '' }} Kategori
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_category"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Pijat Bayi"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form_category.name"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/category.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data untuk membuat kategori di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save_category"
                        @click="save_category"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form_category = false; reset_category();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.delete_category"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.delete_category = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.delete_category = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Kategori
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search_category"
                  @click:clear="search_category = ''; fetchCategory();"
                  v-on:keyup.enter="fetchCategory()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>

                <v-card-text
                  class="pb-0"
                  style="height: calc(100vh - 130px); overflow: auto;">
                  <v-row>
                    <v-col
                      cols="12">
                      <v-skeleton-loader
                        v-for="(item, index) in process.category ? 5 : category"
                        :key="index"
                        :loading="process.category"
                        :class="index < category.length - 1 ? 'mb-4' : ''"
                        type="list-item-avatar-three-line">
                        <template>
                          <div>
                            <v-list
                              v-if="!process.category && category.length > 0"
                              flat
                              dense
                              color="#FFFFFF40"
                              class="border-radius">
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="body-2 font-weight-bold text-capitalize">
                                    {{ item.name }}
                                  </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-btn
                                    @click="selected.category = item; dialog.deletes_category = true;"
                                    icon
                                    color="red"
                                    elevation="0">
                                    <v-icon color="red">mdi-close-circle</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </div>
                        </template>
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>

                  <Empty v-if="!process.category && category.length < 1"
                    class="py-6"
                    margin="my-16"
                    size="125"
                    message="Kategori" />
                </v-card-text>
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          deletes: false,
          deletes_category: false,

          go_back: false
        },
        sheet: {
          form: false,
          detail: false,

          form_category: false,

          delete_category: false
        },
        selected: {
          category: ''
        },
        search: '',
        search_category: '',
        detail: {},
        category_parent: [],
        category_tree: [],
        category: [],
        material: [],
        list: [],
        pagination: {},

        limit: 10,

        upload: {
          folder: null
        },

        select_category: false,

        form: {
          id: '',
          category_id: '',
          category_name: '',
          name: '',
          image_url: '',
          type: 'service',
          duration_minutes: 0,
          retail_price: 0,
          special_price: 0,
          stock: 0,
          stock_min: 0,

          // STOK PER TOKO
          store: [],

          // ADVICE
          advice: '',
          material: [],

          composition: [],
          customer_prepare: []
        },

        menu_category: ['Kategori', 'Sub Kategori'],
        form_category: {
          id: '',
          type: 'Kategori',
          par_id: 0,
          name: ''
        },

        process: {
          load: false,
          form: false,
          file: false,

          limit: false,

          category: false,

          material: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/layanan',
          title: 'Layanan',
          description: 'Layanan Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.image_url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      },

      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,
          'type[eq]': 'service'
        }

        await this.$axios.$get(`${process.env.API}admin/item`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/item/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },

      async fetchCategory () {
        this.process.category = true

        let params = {
          search: this.search_category
        }

        await this.$axios.$get(`${process.env.API}admin/item-category`, { params })
        .then((response) => {
          this.process.category = false

          if (response.status === 200) {
            this.category = response.results.data
          }
        })
      },

      async fetchMaterial () {
        this.process.material = true

        let params = {
          limit: 10000,
          'type[eq]': 'material'
        }

        this.material = []

        await this.$axios.$get(`${process.env.API}admin/item`, { params })
        .then((response) => {
          this.process.material = false

          if (response.status === 200) {
            // this.material = response.results.data
            let data = response.results.data

            data.map(obj => {
              this.material.push({
                id: obj.id,
                name: obj.name,
                image: obj.image_url
              })
            })
          }
        })
      },

      async fetchCategoryParent () {
        this.process.category = true

        let params = {
          par_id: 0
        }

        await this.$axios.$get(`${process.env.API}admin/item-category`, { params })
        .then((response) => {
          this.process.category = false

          if (response.status === 200) {
            this.category_parent = response.results.data
          }
        })
      },

      async fetchCategoryTree () {
        this.process.category = true

        let params = {

        }

        await this.$axios.$get(`${process.env.API}admin/item-category`, { params })
        .then((response) => {
          this.process.category = false

          if (response.status === 200) {
            this.category_tree = this.changeData(response.results.data)
          }
        })
      },

      changeData (data_array) {
        function filter (array, condition) {
          let result = []

          for (let i = 0; i < array.length; i++) {
            if (condition(array[i])) {
              result.push(array[i])
            }
          }

          return result
        }

        function getChilds (parentKey, items) {
          let subItems = filter(items, function (n) {
            return n.par_id === parentKey
          })

          let result = []

          for (let i = 0; i < subItems.length; i++) {
            let subItem = subItems[i]

            let kids = getChilds(subItem.id, items)
            if (kids.length) {
              subItem.children = kids
            }

            result.push(subItem)
          }

          if (parentKey.length) {
            return result
          } else {
            return result[0]
          }
        }

        const data = data_array

        let richMediaData = getChilds('0', data)

        return richMediaData
      },

      to_value (val) {
        let data = val[0]

        this.form.category_id = data.id
        this.form.category_name = data.name

        this.select_category = false
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          category_id: item !== undefined ? item.category_id : '',
          category_name: item !== undefined ? item.category_name : '',
          name: item !== undefined ? item.name : '',
          type: item !== undefined ? item.type : 'service',
          image_url: item !== undefined ? item.image_url : '',
          duration_minutes: item !== undefined ? item.duration_minutes : 0,
          retail_price: item !== undefined ? item.retail_price : 0,
          special_price: item !== undefined ? item.special_price : 0,
          stock: item !== undefined ? item.stock : 0,
          stock_min: item !== undefined ? item.stock_min : 0,

          // STOK PER TOKO
          store: item !== undefined ? item.store : [],

          // ADVICE
          advice: item !== undefined ? item.advice : '',
          material: item !== undefined ? item.material_json_array : [],

          customer_prepare: item !== undefined ? item.customer_prepare_json_array : [],
        }

        this.process.form = false

        this.message.error = ''

        if (this.sheet.form) {
          // this.fetchCategory()
          this.fetchCategoryTree()

          this.fetchMaterial()
        }
      },

      add_customer_prepare () {
        this.form.customer_prepare.push({value: ''})
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/item/add' : 'admin/item/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false

              if (this.form.id === '') {
                this.fetch()
              } else {
                this.fetchDetail(this.detail.id)
              }

            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      async deletes () {
        this.message.error = ''

        this.process.form = true

          // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/item/delete`, {
          id: this.detail.id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.deletes = false

            this.sheet.detail = false

            this.fetch()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      reset_category (item) {
        if (this.$refs.form_category) this.$refs.form_category.reset()

        this.form_category = {
          id: item !== undefined ? item.id : '',
          type: 'Kategori',
          par_id: item !== undefined ? item.par_id : 0,
          name: item !== undefined ? item.name : ''
        }

        if (item !== undefined) {
          this.form_category.type = item.par_id === '0' ? 'Kategori' : 'Sub Kategori'
        }

        this.process.form = false

        this.message.error = ''

        this.fetchCategoryParent()
      },
      async save_category () {
        this.message.error = ''

        const isValid = await this.$refs.form_category.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form_category.id === '' ? 'admin/item-category/add' : 'admin/item-category/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form_category)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form_category = false

              this.fetchCategory()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form_category.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form_category.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async deletes_category () {
        this.message.error = ''

        this.process.form = true

          // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/item-category/delete`, {
          id: this.selected.category.id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.deletes_category = false

            this.sheet.delete_category = false

            this.fetchCategory()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>

<style>
  .custom_treeview {
    max-width: 360px;
  }

  .custom_treeview .v-treeview-node__content {
    position: relative;
    left: -30px;
  }
</style>
